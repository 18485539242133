.oglasi-headline {
	@include for-mobile-and-tablet {
		flex-wrap: wrap;
		padding: 0px 2px 8px 2px;
	}

	&-left, &-back {
		@include for-mobile-and-tablet {
			width: 100%;
		}
	}

	&-back {
		@include for-mobile-and-tablet {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;

			margin-top: 3px;
			border-bottom: 1px solid #c0c0c0;
		}

		p {
			@include for-mobile-and-tablet {
				margin-right: 1rem;
			}
		}

		a {
			@include for-mobile-and-tablet {
				margin-bottom: 0;
			}
		}
	}

	.vrsta-cene {
		@include for-mobile-and-tablet {
			margin-left: 0;
		}
	}
}

.oglasi-headline-right {
	@include for-mobile-and-tablet {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1vw;

		margin-top: 2vw;

		.oglasi-headline-ad-code-views {
			font-size: 1rem;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		a {
			margin-bottom: 0;

			display: flex;
			flex-direction: column;
			align-items: center;

			i {
				font-size: 7vw;
				margin-right: 0;
			}
		}
	}
}

.oglasi-osnovne-informacije {
	li {
		p {
			@include for-mobile-and-tablet {
				width: 50%;
			}
		}
	}
}

.oglasi-dodatna-oprema {
	li {
		p {
			@include for-mobile-and-tablet {
				width: 100%;
			}
		}
	}
}

.oglasi-dodatne-informacije, .oglasi-info-left {
	@include for-mobile-and-tablet {
		width: 100%;
	}
}

.oglasi-glavne-informacije {
	@include for-mobile-and-tablet {
		width: 100%;
		margin-left: 0;
		border-bottom: 0;
	}
}

.oglasi-glavne-informacije-item {
	@include for-mobile-and-tablet {
		width: 33%;
		border-bottom: 1px solid #ddd;
	}
}

.oglasi-info-photos-mobile {
	@include for-mobile-and-tablet {
		width: 100%;
	}

	.oglasi-imgs {
		@include for-mobile-and-tablet {
			margin-top: 4px;
			position: relative;
			margin: 2px;
		}
	}

	.oglasi-item-tekst-img {
		a {
			@include for-mobile-and-tablet {
				display: block;
			}
		}

		.oglas-item-slika {
			@include for-mobile { width: auto; min-height: 15rem; }
			@include for-tablet { width: auto; min-height: 25rem; }
		}
	}
}

.registracija-info-cijena {
	@include for-mobile-and-tablet {
		font-weight: bold;
	}
}

.oglasi-edit-btn {
	@include for-mobile-and-tablet {
		width: 100%;
	}
}