.report-form {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;

  @include for-mobile-and-tablet {
    flex-direction: column;
  }
}

.report-column {
  width: 50%;

  @include for-mobile-and-tablet {
    width: 100%;
  }
}

.report-row {
	padding: 10px;

	[type="radio"] {
			padding: 3px;
			margin-right: 5px;
	}

	em {
			font-weight: 700;
			color: #D05;
	}
}

.report-buttons {
	button {
			font-family: 'Trebuchet MS';
			font-size: 16px;
			font-weight: bold;
			border: 0;
			outline: 0;
			cursor: pointer;
			text-transform: uppercase;
			color: #fff;
			padding: 10px;
			border-radius: 3px;
	}
}

.report-button {
	&__close {
			background: #101010;
			margin-right: 2px;

			&:hover {
					opacity: 0.8;
			}
	}

	&__submit {
			background: $color-primary;

			&:hover {
					opacity: 0.8
			}
	}
}

.report-comment, .report-email {
	padding: 15px 0 15px 15px;
}

.report-comment textarea {
	width: 100%;
}

.report-email input {
	width: 100%;
	padding: 4px 6px;
}

.report-buttons {
	float: right;
}