.page-header {
	@extend .oglasi-header;
	width: 100%;

	h1 {
		text-transform: uppercase;
		color: $color-white;
		display: inline-block;
		font-size: 14px;
		margin: initial;
		line-height: 145%;
		font-weight: normal
	}
}

.registracija-content,
.dodaj-oglas-content {
	@extend .oglasi-content-oglas;
	height: auto;
	position: relative;

	.heading {
		font-size: 18px;
		font-family: 'Tahoma';
		font-weight: bold;
		margin-top: 10px;
	}

	.form-group {
		&.fullWidth {
			width: 100%;

			input {
				width: 100%;
			}
		}

		&_single {
			position: relative;
		}

		&_error {

			input[type="text"],
			input[type="number"],
			input[type="password"],
			input[type="email"],
			input[type="time"],
			select,
			div {
				border: 1px solid #e91c23;
			}

			.form-telefon__container {
				border-radius: 18px;

				input[type="text"] {
					border: 0;
				}
			}
		}

		&__mask {
			background: #c0c0c0;
			padding: 8px;
			display: inline-block;
			line-height: 18px;
			border-radius: 18px 0 0 18px;
			width: 25%;

			@include for-mobile-and-tablet {
				font-size: 1.25rem;
				text-align: center;
			}
		}

		.form-group__phone {
			border-radius: 0 18px 18px 0;

			@include for-mobile-and-tablet {
				font-size: 1.25rem;
			}
		}

		&__error-message {
			border: none;
			color: #e91c23;
		}
	}
}

.registracija-content-upper {
	text-align: center;
	padding: 0px 100px;
	margin-bottom: 20px;

	@include for-mobile {
		padding: 0 1rem;
	}
}

.registracija-item {
	text-align: center;
	width: 48%;
	float: left;
	border: 1px solid #c0c0c0;
	margin-left: 1.3%;
	margin-bottom: 12px;

	a {
		color: $color-text;
		padding: 20px 5px;
		width: 100%;

		@include hover-active-focus {
			background: #eee;
		}

		.lead {
			min-height: 40px;
		}
	}

	i {
		font-size: 70px;
	}
}

.registracija-profila-tabovi {
	width: 100%;
	display: flex;
	text-align: center;

	@include for-mobile {
		overflow-x: auto;
		overflow-y: hidden;
	}
}

.registracija-profila-tabovi-item {
	font-family: 'Trebuchet MS';
	font-weight: bold;
	font-size: 16px;
	border: 1px solid #c0c0c0;
	border-left: 0;
	border-top: 0;
	width: 20%;
	height: 7rem;
	position: relative;

	@include for-mobile {
		width: 30%;
	}

	@include hover-active-focus {
		border-bottom: 0;

		&::before {
			content: '';
			position: absolute;
			left: 1px;
			top: 100%;
			width: 52.5%;
			height: 1px;
			background: #c0c0c0;
			-webkit-transform: rotate(15deg) translateY(9px);
			transform: rotate(15deg) translateY(9px);
		}

		&::after {
			content: '';
			position: absolute;
			right: 1px;
			top: 100%;
			height: 1px;
			width: 52.5%;
			background: #c0c0c0;
			-webkit-transform: rotate(-15deg) translateY(9px);
			transform: rotate(-15deg) translateY(9px);
		}
	}

	i {
		font-size: 60px;
	}

	a {
		color: $color-text;
		width: 100%;
		height: 100%;

		@include hover-active-focus {
			color: $color-primary;
		}
	}

	p {
		@include for-mobile {
			width: 6rem;
			line-height: 100%;
		}
	}

	&.active {
		border-bottom: 0;

		a {
			color: $color-primary;
		}

		&::before {
			content: '';
			position: absolute;
			left: 0px;
			top: 100%;
			width: 52.5%;
			height: 1px;
			background: #c0c0c0;
			-webkit-transform: rotate(15deg) translateY(9px);
			transform: rotate(15deg) translateY(9px);

			@include for-mobile {
				display: none;
			}
		}

		&::after {
			content: '';
			position: absolute;
			right: 0px;
			top: 100%;
			height: 1px;
			width: 52.5%;
			background: #c0c0c0;
			-webkit-transform: rotate(-15deg) translateY(9px);
			transform: rotate(-15deg) translateY(9px);

			@include for-mobile {
				display: none;
			}
		}

		@include for-mobile {
			border-bottom: 1px solid #c0c0c0;
		}
	}
}

.registracija-content-form {
	float: left;
	width: 100%;
	padding: 30px 25px;
}

.registracija-content-heading {

	margin-bottom: 20px;

	.heading {
		font-family: 'Tahoma';
		font-weight: bold;
		font-size: 18px;
	}
}

.registracija-korisnik-form {
	width: 50%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	position: relative;

	label {
		display: block;
	}

	input[type="text"],
	input[type="number"],
	input[type="password"],
	input[type="email"],
	input[type="time"] {
		display: inline-block;
		width: 100%;
		background: #ffffff;
		height: 2.5rem;
		border: 1px solid #c0c0c0;
		padding: 8px;
	}

	input[type="radio"] {
		display: inline-block;
		position: relative;
		text-align: center;
		outline: none;
		color: #999;

		&:checked {
			color: $color-text;
		}

		&::after {
			font-family: 'fonticons';
			position: absolute;
			top: -3px;
			left: 0px;
			font-size: 60px;
			background: #fff;
		}

		&.zenski-pol-radio {
			&::after {
				content: "\E052";
			}
		}

		&.muski-pol-radio {
			&::after {
				content: "\E051";
			}
		}
	}

	.form-group {
		margin-bottom: 15px;
	}

}

.sms-code {
	&__container {
		overflow: hidden;
		float: left;
		width: 317px;
		position: relative;
		clear: both;
	}

	&__help-block {
		overflow: hidden;
	}

	&__support-text {
		float: right;
		width: 50%;
		margin-top: 10px;
	}

	&__left-column {
		float: left;
		width: 50%;
		margin-top: 10px;
	}

	&__resend-button {
		cursor: pointer;
		text-decoration: none;
		text-align: center;
		border-radius: 3px;
		width: 49%;
		padding: 6px;
	}

	&__row {
		&_error {
			border: 1px solid #e91c23;
		}
	}

	&__fallback-text,
	&__resend-button,
	&__timer {
		font-family: 'Calibri', sans-serif;
	}
}

.registration-form {
	clear: both;
	text-align: center;
	width: 50%;
	font-size: 18px;
	padding-top: 15px;
	overflow: hidden;
	position: relative;

	&__error-text {
		color: #e91c23
	}
}

.required {
	color: #e91c23;
}

.lead-label {
	color: #ababab;
	margin-left: 3px;
}

.form-pol {


	label {
		&:first-of-type {
			float: left;
			width: 100%;
		}

	}

}

.form-email,
.form-ime,
.form-ime-prezime,
.form-pol,
.form-industrija,
.form-drzava,
.form-lokacija,
.form-telefon,
.form-contact,
.form-sifra {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	justify-content: space-between;

	.form-group {
		width: 48%;
	}
}

.form-ime-prezime,
.form-lokacija,
.form-email,
.form-telefon,
.form-contact {

	input[type='text'],
	input[type='email'] {
		border-radius: 20px;
	}
}

.form-telefon {
	.form-group {
		display: flex;
		flex-wrap: wrap;
	}

	input[type='text'] {
		width: 67%;
		padding: 0 5px;
		height: 2.5rem;
		display: inline-block;
		border-left: 0;
		border-radius: 20px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	&-old {
		input[type='text'] {
			border-radius: 20px;
			border-left: 1px solid #c0c0c0;
		}
	}

	label {
		width: 100%;
	}

	.select-wrap {
		border: 1px solid #dcdae5;
		width: 33%;
		display: flex;
		justify-content: space-between;
		height: 2.5rem;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		align-items: center;
		background-position: 90%;

		label {
			width: auto;
			margin-left: 5px;
		}
	}

	.dial-select {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		opacity: 0;
	}
}

.form-sifra {
	input[type='password'] {
		border-radius: 18px;
	}
}

.form-pol {
	justify-content: start;

	.form-group {
		width: 20%;

		p {
			margin-top: 47px;
			margin-left: 10px;
		}
	}
}

.form-telefon {
	&__row {
		width: 100%;
		display: flex;
	}

	&__container {
		display: flex;
	}

	.sms-potvrda {
		margin-bottom: 10px;
	}

	.form-group--phone {
		margin-right: 0;
		width: 48%;
		float: left;
		margin-bottom: 15px;

		.form-group__phone[type='text'] {
			width: 100%;
		}

		&-hideBtn {
			@extend .form-group--phone;
			margin-right: 30px;
		}
	}

	&__hideBtn {
		float: left;
		margin: 25px 8px;
		color: #999999;
		cursor: pointer;

		&:hover {
			color: #101010;
		}

		&-hidden {
			color: #101010;

			&:hover {
				color: #999999;
			}
		}
	}
}

.form-drzava {
	optgroup {
		font-family: 'Calibri';
		color: #a395a4;
		font-weight: 300;

		option {
			font-family: 'Calibri';
			color: black;
		}
	}
}

.form-contact-phone {
	width: 48%;
}

.form-submit-btn {
	background: $color-text;
	color: $color-white;
	border: 0;
	width: 100%;
	text-align: center;
	padding: 11px;
	text-transform: uppercase;
	font-family: 'Trebuchet MS';
	font-weight: bold;
	font-size: 16px;
	cursor: pointer;
	margin-top: 10px;
	border-radius: 4px;
	max-height: 45px;

	@include hover-active-focus {
		background: $color-primary;
	}

	&:disabled {
		background: #c0c0c0;

		&:hover {
			cursor: not-allowed;
		}
	}

	&-blocked {
		display: none;
	}
}

.btn-submit-lead {
	line-height: 120%;
	margin-top: 5px;

	a {
		color: $color-text;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: -2px;
			left: 0;
			width: 100%;
			height: 2px;
			background: #7b7b7b;
		}
	}
}

.info-polje {
	border: 1px solid #c0c0c0;
	position: absolute;
	width: 303px;
	left: 110%;
	z-index: 10;
	background-color: white;
	padding: 0 15px 15px 15px;
	top: 0;

	.heading {
		color: $color-primary;
		font-family: 'Calibri';
		position: relative;
		display: block;

		&::after {
			content: '';
			display: block;
			margin: 7px 0;
			width: 100%;
			height: 1px;
			background: #ccc;
		}
	}
}

.sms-potvrda {
	float: left;
	width: 100%;

	.potvrda-msg {
		margin-top: 20px;
		margin-bottom: 10px;
		color: $color-white;
		padding: 5px 30px;
		font-size: 16px;
		text-align: center;
		background: #e91c23;
		border-radius: 4px;
	}

	.help-block {
		overflow: hidden;
	}

	.unesi-kod-input {
		border: 1px solid $color-text !important;
		padding: 10px !important;
		width: 50% !important;
		text-align: center !important;

		&_error {
			border: 1px solid red !important;
		}
	}

	.potvrdi-btn {
		width: 49%;
		height: 37px;
		margin-left: 3px;
		background: $color-text;
		color: $color-white;
		border: 0;
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
		border-radius: 3px;
		cursor: pointer;
		font-family: 'Calibri';

		@include hover-active-focus {
			background: $color-primary;
		}

		&:disabled {
			background: #c0c0c0;

			&:hover {
				cursor: not-allowed;
			}
		}
	}

}

.korisnicki-profil-small-heading {
	float: left;
	width: 100%;
	font-family: 'Trebuchet MS';
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	margin-top: 15px;
	margin-bottom: 10px;

	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		background: $color-text;
	}
}

.form-postanski-broj {
	float: left;
	width: 100%;
}

.form-datum-rodjenja {
	.form-group {
		width: 31.5%;
		margin-right: 8px;
		display: inline-block;

		select {
			width: 100%;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}
}

.korisnicki-profil {

	.registracija-content-welcome {
		font-family: 'Tahoma';
		font-weight: bold;
		font-size: 18px;
		text-align: center;
		float: left;
		width: 100%;
		margin-bottom: 30px;

		span {
			color: $color-primary;
		}
	}

	.registracija-content-success-msg {
		float: left;
		width: 100%;
		background: #15b500;
		text-transform: uppercase;
		font-size: 16px;
		padding: 20px;
		text-align: center;
		font-weight: bold;
		border: 2px dashed #000;
		margin-bottom: 10px;
	}

	.registracija-content-tip {
		float: left;
		width: 100%;
		background: #ffff00;
		font-size: 16px;
		padding: 10px;
		text-align: center;
		border: 2px dashed #000;
		margin-bottom: 10px;

		h3 {
			font-size: 16px;
			font-weight: bold;
			color: $color-secondary;
			text-transform: uppercase;
		}
	}

	.registracija-content-napomena {
		float: left;
		width: 100%;
		font-size: 16px;
		padding: 10px;
		text-align: center;
		border: 2px dashed $color-secondary;
		margin-bottom: 10px;

		h3 {
			font-size: 16px;
			font-weight: bold;
			color: $color-secondary;
			text-transform: uppercase;
		}
	}

	.registracija-content-paznja {
		float: left;
		width: 100%;
		font-size: 16px;
		background: $color-secondary;
		padding: 10px;
		text-align: center;
		border: 2px dashed #000;
		margin-bottom: 10px;
		color: $color-white;

		&.last {
			margin-bottom: 40px;
		}

		h3 {
			font-size: 16px;
			font-weight: bold;
			text-transform: uppercase;
		}
	}

	.registracija-content-uplatnica {
		float: left;
		width: 100%;
		font-size: 16px;
		background: #ffd4ef;
		padding: 10px 30px;
		text-align: center;
		border: 2px dashed #000;
		margin-bottom: 10px;

		@include for-mobile {
			padding: 5px
		}

		h3 {
			font-size: 16px;
			font-weight: bold;
			text-transform: uppercase;
			color: $color-secondary;
			margin-bottom: 10px;
		}

		.half {
			float: left;
			width: 48%;

			&:first-of-type {
				margin-right: 4%;

				.polje-inner-no-border {
					border-bottom: 1px solid #000;
					margin-top: 40px;
				}
			}

			&:last-of-type {
				.polje {
					margin-bottom: 5px;
				}
			}

			.polje {
				margin-bottom: 22px;
				float: left;
				width: 100%;
			}

			.polje-broj-racuna {
				.polje-inner {
					padding: 2px;
				}
			}

			.polje-inner {
				font-size: 22px;
				font-weight: bold;
				border: 1px solid #000;
				padding: 15px;

				@include for-mobile {
					font-size: 1rem;
				}
			}

			.polje-inner-no-border {
				border-bottom: 1px solid #000;
				margin-top: 30px;
			}

			.polje-info {
				font-size: 11px;
				margin-top: 3px;
				font-family: 'Open Sans'
			}

			.polje-half {
				width: 46%;
				float: left;
			}

			.polje-half-first {
				margin-right: 8%;
			}

			.polje-small {
				width: 20%;
				float: left;
				margin-right: 5%;
			}

			.polje-wide {
				width: 75%;
				float: left;
				margin-bottom: 8px;
			}

			.polje-label {
				width: 20%;
				float: left;
				line-height: 32px;
			}

			.polje-iznos {
				width: 45%;
				float: left;
				margin-right: 5%;
				margin-bottom: 5px;

				.polje-inner {
					padding: 2px 0px 1px 0px;
				}
			}

			.polje-sifra {
				width: 30%;
				float: left;
			}
		}
	}

	.info-adresa-stanovanja {
		top: 80px;
	}

	.info-ime,
	.info-prezime {
		display: none; // REMOVE THIS
	}

	.info-drzava,
	.info-grad,
	.info-lokacija,
	.info-ulica {
		top: 190px;
		display: none; // REMOVE THIS
	}

	.info-email,
	.info-telefon,
	.info-aplikacije {
		top: 360px;
		display: none; // REMOVE THIS
	}

	.info-sifra {
		display: none; // REMOVE THIS
		top: 795px;
	}

	.info-login {
		display: none;
		top: 190px;
	}

}

.info-verifikacija {
	position: absolute;
	width: 303px;
	left: 110%;
	background: blue;
	padding: 4px;
	color: $color-text;
	text-align: center;
	font-size: 16px;
	border-radius: 3px;
	top: 20px;

	&.info-verifikacija-false {
		background: #ffb400;

		a {
			color: $color-text;
			padding: 3px;
			display: block;
		}

		@include hover-active-focus {
			background: #15b500;
		}
	}

	&.info-verifikacija-true {
		background: #15b500;
	}
}

.registracija-table {
	width: 100%;
	margin-bottom: 20px;

	table {
		border-collapse: collapse;
		margin-bottom: 5px;
	}

	td,
	th {
		border: 1px solid #b3b3b3;
		padding: 10px 5px;
		text-align: center;
	}

	td.invisible-cell {
		border-left: none;
		border-bottom: none;
		border-right: none;
	}

	tr:nth-of-type(11) {
		td:last-of-type {
			padding: 10px 2px;
		}
	}

	th {
		border: 1px solid #004171;
	}

	th {
		font-family: 'Trebuchet MS';
		text-transform: uppercase;
		font-size: 16px;
		font-weight: bold;
		background: $color-primary;
		color: $color-white;
	}

	i {
		font-size: 18px;
	}

	.ico-nema {
		color: #999;
	}

	tr {

		&:nth-of-type(even) {
			background: #eee;
		}

		td {
			font-family: 'Trebuchet MS';
			font-weight: bold;
			min-width: 71px !important;

			&:first-of-type {
				font-family: 'Calibri';
				font-weight: normal;
				text-align: left;
				padding: 10px 19px 10px 10px;
			}
		}
	}

	.popust-highlight-10 {
		color: #ff7800;
	}

	.popust-highlight-20 {
		color: #e91c23;
	}

	.pro {
		position: relative;
		padding-left: 40px;

		&::after {
			content: '';
			background: url(/img/sprite.png) no-repeat;
			background-position: 0px -40px;
			width: 34px;
			height: 12px;
			position: absolute;
			top: 3px;
			left: 0;
		}
	}

	strong {
		text-transform: uppercase;
	}

	a,
	button {
		background: $color-primary;
		border-radius: 4px;
		color: $color-white;
		font-weight: bold;
		text-transform: uppercase;
		padding: 5px;

		@include hover-active-focus {
			background: $color-text;
		}
	}

	button {
		border: none;
		background: #999;

		@include hover-active-focus {
			background: $color-primary;
		}
	}

	.package-categories-search {
		width: 100%;
		padding: .5rem;
		border: 1px solid #b3b3b3;
	}
}

.tabela-izabrano {
	a {
		background: #999;
	}

	.izabran-paket {
		position: relative;
		padding: 0;
		background: #e91c23;
		height: 45px;

		a {
			background: #e91c23;
			padding: 3px;
		}

		&::after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			top: 100%;
			left: 0;
			border-left: 36px solid transparent;
			border-right: 36px solid transparent;
			border-top: 10px solid #e91c23;
		}
	}
}

.dodaj-logo {
	background: #999;
	color: $color-white;
	text-transform: uppercase;
	padding: 6px 10px;
	border-radius: 3px;
	margin-top: 18px;
	font-family: 'Trebuchet MS';
	font-weight: bold;
	font-size: 16px;
	cursor: pointer;

	i {
		vertical-align: bottom;
		margin-right: 5px;
	}

	@include hover-active-focus {
		background: $color-primary;
	}
}