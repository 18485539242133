.form-group-closer-location {
	.select-dropdown-picker {
		background: #fff;;
		height: 2.5rem;
		padding: 9px;
		border: 1px solid #c0c0c0;

		input[type="text"], label {
			overflow: hidden;
			text-overflow: ellipsis;
		}

		label {
			min-height: 16px;
			font-weight: normal;
			font-size: 14px;
			font-family: 'Calibri';
			display: inline-block;
    	white-space: nowrap;
		}

		input[type="text"] {
			// border: none;
		}

		.accept {
			position: absolute;
			top: 0;
    	right: 3px;
			cursor: pointer;

			i {
				font-size: 1.75rem;
			}

			&-disabled {
				cursor: not-allowed;
				color: #ccc;
			}
		}
	}
}