
.sacuvani-korisnici {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 3px;
}

.sacuvani-korisnici-item {
	width: 24.5%;
	border: 1px solid #d9d9d9;
	padding: 3px 3px 3px 5px;
	position: relative;
	font-family: 'Trebuchet MS';
	margin-bottom: 5px;
	margin-right: .35rem;

	&:nth-of-type(4n) {
			margin-right: 0;
	}

	&.sacuvani-korisnici-item-prodavnica {
			.sacuvani-korisnik-img {
					width: 78px;

					img {
							margin-top: 2px;
							margin-bottom: 6px;
					}
			}

			.sacuvani-korisnik-info {
					width: 140px;
			}
	}

	&.pro-korisnik {
			background: #fffff0;

			.sacuvani-korisnik-info {
					p:nth-of-type(2) {
							position: relative;
							display: inline-block;

							&::after {
									content: '';
									background: url(/img/sprite.png) no-repeat;
									background-position: 0px -40px;
									width: 34px;
									height: 12px;
									position: absolute;
									top: 4px;
									right: -40px;
							}
					}
			}

	}

	.obrisi-sacuvanog-korisnika {
			position: absolute;
			right: 4px;
			top: 1px;
			color: $color-text;

			i {
					font-size: 16px;
			}

			@include hover-active-focus {
					color: #e91c23;
			}
	}
}

.sacuvani-korisnik-img {

	float: left;
	width: 60px;
	margin-right: 3px;
	min-height: 70px;

	i {
			font-size: 55px;
			margin-top: -7px;
	}

	img {
			margin-top: 6px;
	}
}

.sacuvani-korisnik-info {

	float: left;
	width: 155px;

	p {
			font-weight: bold;
	}

	.online-status {

			font-size: 12px;
			margin-top: 3px;

			&.online {
					span {
							background: #139e00;
					}
			}

			&.offline {
					span {
							background: #e91c23;
					}
			}

			span {
					width: 11px;
					height: 11px;
					display: inline-block;
					border-radius: 50%;
					vertical-align: middle;
			}
	}
}

.sacuvani-korisnik-lokacija-link {
	float: left;
	width: 100%;
	font-size: 12px;

	i {
			vertical-align: top;
	}

	p {
			display: inline-block;
			vertical-align: middle;
	}

	.sacuvani-korisnik-lokacija {
			float: left;
			margin-top: 5px;
	}

	.sacuvani-korisnik-link {
			float: right;

			a {
					color: $color-text;
					border: 1px solid #d9d9d9;
					background: #eee;
					padding: 5px 13px;
					border-radius: 3px;

					@include hover-active-focus {
							background: $color-primary;
							color: $color-white;
							border: 1px solid $color-primary;
					}
			}
	}
}