.online-payment {
	@extend .dodavanje-oglasa-form-podaci;

	&__fields {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;

		.form-group {
			width: 33%;
			margin-right: .5%;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.tipovi-oglasa {
		justify-content: flex-end;
	}

	.tip-oglasa-item {
		width: 30%;
		margin-right: 1%;
    display: flex;
    flex-direction: column;
		flex-grow: 1;

		&:last-of-type {
			margin-right: 0;
		}

		a {
			cursor: pointer;
			background-color: #101010;
			color: #fff;
			padding: 8px 30px;
			border-radius: 3px;
			font-size: 18px;
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 10px;
			margin-top: auto;
			margin-left: 0;
			transform: none;
			align-self: center;

			@include hover-active-focus {
				background-color: $color-primary;
			}
		}
	}

	.tip-oglasa-naziv {
		font-size: 1.2rem;
	}

	.tip-oglasa-cena {
		font-size: 2rem;
	}

	.tip-oglasa-lista {
		li {
			margin-bottom: 5px;
		}
	}

	&-app {
		.form-group {
			width: 100%;
			margin-right: 0;
		}

		.tip-oglasa-item {
			width: 100%;
			
			margin-right: 0;
		}

		.tip-oglasa-naziv {
			font-size: 24px;
		}

		.tipovi-oglasa {
			flex-direction: column;
		}
	}

	optgroup {
		color: #a395a4;
		font-weight: 300;

		option {
			color: #666;
		}
	}


	.oglas-container {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.moji-oglasi-item {
    float: none;
    display: flex;
    flex-wrap: nowrap;
		align-items: center;
	}

	.moji-oglasi-item-heading {
		font-family: 'Tahoma';
		font-size: 18px;
		font-weight: bold;
		color: $color-text;
		margin: .2rem 0;
	}

	.oglas-item {
		padding: .4rem;
		margin-bottom: 1rem;
		display: flex;
    justify-content: space-between;

		&__left, &__right {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__right {
			max-width: 75%;
			margin-left: auto;
		}

		&__left {
			width: 25%;
			margin: .3rem;
		}
	}

	.oglas-item-ad-code {
		font-size: 1rem;
		font-weight: bold;
		margin-bottom: .5rem;
	}
}