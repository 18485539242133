.main-content-extended {
	.oglasi-item-tekst {
		width: 19.7%;

		&:nth-of-type(5n + 5) {
			margin-right: 0;
		}

		@include for-mobile {
			width: 49%;

			&:nth-of-type(2n + 2) {
				margin-right: 0;
			}
		}

		@include for-tablet {
			width: 33%;

			&:nth-of-type(3n + 3) {
				margin-right: 0;
			}
		}

		@include for-mobile-and-tablet {
			margin: 0;
		}

		&-banner {
			width: 100%;
		}
	}

	.oglas-item-slika {
		width: auto;
		height: 145.5px;
	
		@include for-desktop {
			width: 234px;
			height: 175.5px;
		}
	}

	.oglasi-item-list-img {
		width: 194px;
		height: 145.5px;
		
		@include for-desktop {
			width: 234px;
			height: 175.5px;
		}
	}
}