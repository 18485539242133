.oglasi-datum {
	font-size: 1rem;
	float: right;

	i {
		margin-right: 5px;
		vertical-align: top;
	}

	p {
		display: inline-block;
	}
}

.oglasi-content-oglas {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	// flex-direction: column;
	border: 1px solid #c0c0c0;
}

.oglasi-headline {
	display: flex;
	// align-items: flex-end;
	width: 100%;
	padding: 0 15px 8px 15px;
	position: relative;

	&-left {
		width: 80%;
		align-self: flex-start;
	}

	&-model {
		font-family: 'Tahoma';
		font-weight: bold;
		font-size: 22px;
		margin-bottom: 5px;
		margin-top: 0px;
	}

	.oglasi-headline-price-rent {}

	.cena-akcija {
		font-family: 'Tahoma';
		font-size: 18px;
		margin-left: 15px;
	}

	.cena {
		font-family: 'Tahoma';
		font-size: 18px;
		padding: 2px 6px 2px 13px;

		&::after {
			border-top: 30px solid #101010;
		}

		&::before {
			width: 4px;
			left: 0;
		}
	}

	.vrsta-cene {
		display: inline-block;
		margin-left: 15px;
		font-family: 'Tahoma';
		font-size: 18px;
		font-weight: bold;
	}
}

.oglasi-headline-right {
	width: 20%;
	bottom: 4px;
	text-align: right;

	a {
		color: $color-text;
		margin-bottom: 8px;

		p {
			display: inline-block;
			text-transform: uppercase;
		}

		@include hover-active-focus {
			color: $color-primary
		}
	}

	i {
		font-size: 20px;
		vertical-align: bottom;
		margin-right: 5px;
	}
}

.oglasi-info-and-img {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}

.oglasi-imgs {
	margin-right: 8px;
	margin-top: 4px;
	position: relative;
	cursor: pointer;

	.ukupno-slika {
		position: absolute;
		bottom: 0;
		right: 0;
		background: rgba(0, 0, 0, .5);
		padding: 1px 6px;
		color: #fff;

		&::after {
			content: '';
			position: absolute;
			left: -4px;
			top: 0;
			width: 0;
			height: 0;
			border-bottom: 28px solid rgba(0, 0, 0, .5);
			border-left: 4px solid transparent;
		}

		p {
			display: inline-block;
			font-family: 'Tahoma';
			font-weight: bold;
			font-size: 18px;
			vertical-align: middle;
		}

		i {
			margin-left: 5px;
			vertical-align: middle;
		}
	}

	.sold {
		background: rgba(0, 0, 0, .5);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 190px;

		@include for-desktop {
			height: 260px;
		}

		@include for-mobile-and-tablet {
			height: 15rem;
		}

		&::after {
			content: 'PRODATO';
			background-color: $color-secondary;
			color: $color-white;
			padding: .1rem .5rem;
			border-radius: 4px;

			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;

			font-size: 2rem;
			opacity: 1;
			font-weight: bold;
		}
	}
}

.oglasi-share {
	width: 248px;

	@include for-laptop-and-desktop {
		width: 340px;
	}

	display: flex;
	justify-content: flex-start;

	margin-right: 6px;
	margin-top: 3px;

	&>.kontakt-icon {
		float: none;
	}
}

.oglasi-edit-btn {
	width: 248px;

	@include for-laptop-and-desktop {
		width: 340px;
	}

	padding: .7rem;
	border-radius: 4px;
	margin-top: 3px;
	margin: 3px 9px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	font-size: 16px;
	text-decoration: none;
	background: #e91c23;
}

.oglasi-add-to-cart-btn {
	width: 248px;

	@include for-laptop-and-desktop {
		width: 340px;
	}

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	background-color: $color-orange;

	padding: .7rem;
	border: none;
	outline: none;
	border-radius: 4px;
	margin-top: 3px;
	margin: 3px 9px;

	&:hover {
		background-color: $color-primary;
		cursor: pointer;
	}

	&_icon {
		color: $color-white;

		.fil0,
		.fil1 {
			fill: $color-white;
		}
	}

	&_text {
		margin-left: 10px;
		font-size: 1.2rem;
		color: $color-white;
	}
}

.oglasi-ponuda {
	background: #f9f9f9;
	border: 1px solid #ddd;
	float: right;
	width: 250px;
	margin-right: 8px;
	margin-top: 3px;
	padding: 5px 10px 10px 10px;
}

.oglasi-ponuda-header {
	font-family: 'Trebuchet MS';
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 5px;

	p {
		display: inline-block;
	}

	i {
		font-size: 16px;
		vertical-align: top;
		margin-left: 7px;
	}
}

.oglasi-ponuda-content {
	.cena {
		margin-left: 4px;
	}

	.vrsta-cene {
		font-family: 'Trebuchet MS';
		font-weight: bold;
		display: inline-block;
		margin-left: 8px;
	}

	.unos-ponude {
		margin-top: 8px;

		input {
			background: #e91c23;
			border: 0;
			outline: none;
			color: $color-white;
			display: inline-block;
			font-family: 'Trebuchet MS';
			font-size: 12px;
			font-weight: bold;
			padding: 6px 5px;
			width: 92px;
		}

		a {
			background: $color-text;
			color: $color-white;
			font-family: 'Trebuchet MS';
			font-size: 12px;
			font-weight: bold;
			padding: 6px;
			margin-left: 6px;
			position: relative;

			@include hover-active-focus {
				background: $color-primary;

				&::after {
					border-bottom: 26px solid $color-primary;
				}
			}

			&::after {
				content: '';
				position: absolute;
				left: -5px;
				top: 0;
				width: 0;
				height: 0;
				border-bottom: 26px solid $color-text;
				border-left: 5px solid transparent;
			}
		}

		.test {
			position: relative;
			display: inline-block;

			&::after {
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				top: 0;
				right: -5px;
				border-top: 26px solid #e91c23;
				border-right: 5px solid transparent;
			}
		}

		::-webkit-input-placeholder {
			color: $color-white;
		}

		:-moz-placeholder {
			color: $color-white;
		}

		::-moz-placeholder {
			color: $color-white;
		}

		:-ms-input-placeholder {
			color: $color-white;
		}

		i {
			font-size: 23px;
			vertical-align: bottom;
			margin-left: 6px;
			color: #a4a4a4;
		}
	}
}

.oglasi-cijena-registracije {
	@extend .oglasi-ponuda;

	width: 83%;
	padding: 0;

	.oglasi-cijena-registracije-content {
		padding: 5px 10px 10px 10px;
	}

	li {
		display: block;
		color: #333;

		span {
			text-transform: uppercase;
			font-weight: bold;
			background: $color-primary;
			font-family: 'Open Sans';
			font-size: 11px;
			color: $color-white;
			padding: 0px 5px 2px 5px;
			border-radius: 4px;

			&:first-of-type {
				margin-left: 7px;
			}
		}
	}

	.registracija-info-cijena,
	.registracija-info-broj {
		font-weight: bold;
	}
}

.oglasi-cijena-registracije-header {

	background: $color-text;
	color: $color-white;
	padding: 3px;

	p {
		display: inline-block;
		font-family: 'Trebuchet MS';
		text-transform: uppercase;
		font-weight: bold;
		font-size: 14px;
		vertical-align: middle;
	}

	i {
		margin-left: 5px;
		margin-right: 2px;
		vertical-align: middle;
		font-size: 16px;
	}
}

.oglasi-info-left {
	width: 55%;
}

.oglasi-info-right {
	width: 45%;

	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.oglasi-glavne-informacije {
	width: 98%;
	display: flex;
	flex-wrap: wrap;
	background: #f9f9f9;
	border-top: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;
	margin-top: 5px;
	margin-left: 6px;
}

.oglasi-glavne-informacije-item {
	width: 15%;
	flex-grow: 1;
	font-size: 16px;
	text-align: center;
	padding: 5px 0;
	border-right: 1px solid #ddd;

	&:last-of-type {
		border-right: 0;
	}

	i {
		font-size: 30px;
		margin-bottom: 3px;
	}

	img {
		width: auto;
		height: 30px;
		margin-top: 10px;
	}

	p {
		line-height: 120%;
	}

	p:first-of-type {
		font-family: 'Tahoma';
		font-weight: bold;
		font-size: 20px;
	}
}

.oglasi-osnovne-informacije {
	padding: 0 15px 15px 15px;

	&:first-of-type {
		padding-top: 15px;
	}

	ul {
		margin-top: 10px;
	}

	li {
		display: flex;
		margin-bottom: .25rem;

		p {
			width: 35%;
		}

		span {
			width: 65%;
			font-family: 'Trebuchet MS';
			font-weight: bold;
			display: inline-block;
			word-wrap: break-word;
		}
	}

	.rezolucija {
		color: #666;
	}

	.oglasi-kompatibilnosti {
		li {
			display: flex;
			justify-content: space-between;

			p {
				width: 40%;
			}

			span {
				width: 55%;
				position: relative;
			}
		}

		&-item {
			border: 1px dashed #ddd;
			margin-bottom: .3rem;
			padding: .3rem;
		}

		&-product {
			margin-left: 1rem;
			margin-bottom: .4rem;

			img {
				width: 15px;
				margin-bottom: 25px;
				margin-right: 5px;
			}
		}
	}

	.compatibility-years-btn {
		padding: .3rem;
		border-radius: 4px;
		line-height: 14px;
		font-weight: bold;
		border: none;
		outline: none;
		cursor: pointer;

		@include hover-active-focus {
			background: $color-primary;
			color: $color-white;
		}
	}
}

.zamena-list {
	display: inline-block;
	float: right;
	width: 242px;
	margin-top: 4px;
	font-family: 'Trebuchet MS';
	font-weight: bold;
	color: #666;
}

.oglasi-dodatne-informacije {
	@extend .oglasi-osnovne-informacije;

	width: 40%;

	li {
		p {
			width: 100%;
		}

		span {
			width: 100%;
		}
	}
}

.oglasi-garancija {
	@extend .oglasi-dodatne-informacije;
}

.oglasi-dodatna-oprema {
	@extend .oglasi-garancija;
	width: 100%;

	i {
		font-size: 16px;
		margin-right: 4px;
		vertical-align: top;
	}

	ul {
		width: 200px;
		display: inline-block;
		float: left;
	}
}

.oglasi-opis {
	@extend .oglasi-dodatna-oprema;

	.oglasi-opis-text {
		margin-top: 10px;
		white-space: pre-line;
	}
}

.oglasi-slicni-oglasi {
	@extend .oglasi-dodatna-oprema;

	padding: 0 0 14px 4px;

	.oglasi-info-heading {
		margin: 0 0 3px 3px;
	}

	ul {
		margin-top: 4px;

		li {
			margin-bottom: 0;
		}
	}
}

.oglasi-agent {
	@extend .oglasi-dodatna-oprema;

	.oglasi-agent-info {
		display: flex;
		margin-top: 1rem;
	}

	.oglasi-agent-img {
		width: 9.375rem;
		height: 7rem;
		background: no-repeat center center;
		background-size: contain;
	}

	.oglasi-agent-description {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		padding-left: .5rem;

		span {
			font-size: 1rem;
			margin-left: .5rem;
		}

		p {
			margin-bottom: .5rem;
		}
	}
}

.oglasi-info-heading {
	background: #f1f1f1;
	color: #333333;
	display: inline-block;
	font-family: 'Trebuchet MS';
	text-transform: uppercase;
	font-weight: bold;
	padding: 2px 12px;
	position: relative;
	margin-left: -8px;

	&::after {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		top: 0;
		right: -4px;
		border-top: 24px solid #f1f1f1;
		border-right: 4px solid transparent;
	}

	&::before {
		content: '';
		position: absolute;
		width: 4px;
		height: 100%;
		top: 0;
		left: 0;
		background: #222;
	}
}

.oglasi-tab {
	float: left;
	width: 100%;
	padding-bottom: 20px;
}

.oglasi-tab-header {
	ul {
		width: 100%;
		display: flex;
		border-top: 1px solid #c0c0c0;
		border-bottom: 1px solid #c0c0c0;
	}

	li {

		&.active {
			a {
				background: $color-text;
				color: $color-white;
			}
		}

		width: 15%;
		flex-grow: 1;
		text-align: center;
		border-right: 1px solid #c0c0c0;

		a {
			padding: 9px;
			width: 100%;
			color: $color-text;
			background: #f9f9f9;
			font-size: 16px;
			text-transform: uppercase;
			line-height: 145%;

			@include hover-active-focus {
				background: $color-text;
				color: $color-white;
			}
		}

		i {
			font-size: 20px;
			vertical-align: bottom;
			margin-right: 6px;
		}
	}

}

.oglasi-tab-content {
	float: left;
	width: 100%;
	padding-left: 4px;

	.oglasi-item-ikonice {
		// width: 168px;
	}

	.oglasi-item-ikonice-icons-other {
		i {
			margin-left: 5px
		}
	}
}

.oglasi-tab-content-slicni-oglasi,
.oglasi-tab-content-registracija,
.oglasi-tab-content-fotografije,
.oglasi-tab-pitanja {
	&.active {
		display: block;
		width: 100%;
	}
}

.oglasi-tab-content>.oglasi-tab-content-registracija {
	border: none;
}

.oglasi-tab-content-specifikacije {
	// display: none; // REMOVE THIS
	width: 99%;
	margin-left: 2px;
	margin-top: 8px;
	font-size: 11px;
	font-family: 'Open Sans';

	ul {
		border: 1px solid #c0c0c0;
		border-bottom: 1px solid $color-primary;
	}

	li {
		display: block;
	}

	span {
		display: inline-block;
		padding: 2px 8px;
	}

	.naslov {
		width: 150px;
		text-align: center;
		background: #eee;
		border-bottom: 1px solid #c0c0c0;
		border-right: 1px solid #c0c0c0;
	}

	.podatak {
		border-bottom: 1px solid #c0c0c0;
		width: 527px;
		color: #666;
	}
}

.oglasi-tab-content-specifikacije-item {
	margin-bottom: 10px;
}

.specifikacije-header {
	background: $color-text;
	color: $color-white;
	display: inline-block;
	text-transform: uppercase;
	font-family: 'Calibri';
	font-size: 14px;
	text-align: center;
	width: 147px;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		top: 0;
		right: -4px;
		border-bottom: 20px solid $color-text;
		border-right: 4px solid transparent;
	}
}

.oglasi-tab-pitanja {
	display: none;
	padding: 10px 5px;
	float: left;
}

.oglasi-tab-pitanje {
	// border: 2px solid #e2e2e2;
	margin-bottom: 5px;
}

.oglasi-pitanje-user {
	float: left;
	width: 189px;
	font-family: 'Trebuchet MS';
	border-left: 2px solid #e2e2e2;
	border-bottom: 2px solid #e2e2e2;

	.oglasi-pitanje-user-name {
		font-weight: bold;
		padding: 4px 6px;
	}

	.user-avatar {
		font-size: 45px;
		float: left;
		margin-top: -3px;
	}

	.user-online-status {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		display: inline-block;
		margin-right: 5px;
		vertical-align: middle;

		&.offline {
			background: #e91c23;
		}

		&.online {
			background: #139e00;
		}
	}

	.user-status {
		float: left;
		padding: 5px 15px;

		.user-online-status-text {
			font-size: 12px;
		}
	}

	.verifikacije-icons {
		margin-top: 5px;

		i {
			margin-right: 5px;
			font-size: 16px;
		}
	}
}

.oglasi-pitanje-user-name {
	background: #ddd;
}

.oglasi-pitanje-user-status {
	float: left;
	width: 100%;
	padding: 0px 3px 3px 6px;
}

.oglasi-pitanje-msg {
	float: left;
	width: 483px;
	min-height: 86px;
	background: #f9f9f9;
	padding: 5px 10px;
	border: 2px solid #e2e2e2;
}

.oglasi-pitanje-msg-header {
	color: #999;
	position: relative;
	float: left;
	width: 100%;

	&::after {
		content: '';
		width: 0;
		height: 0;
		border-top: 14px solid transparent;
		border-right: 10px solid #f9f9f9;
		border-bottom: 14px solid transparent;
		position: absolute;
		left: -20px;
		top: -6px;
	}

}

.pitanje-date {
	float: left;
	display: inline-block;
}

.pitanje-akcije {
	float: right;
	display: inline-block;

	a {
		color: #999;
		margin-left: 3px;

		@include hover-active-focus {
			color: $color-text;
		}
	}

	i {
		vertical-align: top;
		font-size: 16px;
	}
}

.oglasi-tab-pitanja-item {
	float: left;
}

.oglasi-pitanje-msg-content {
	float: left;
	width: 100%;
	margin-top: 3px;
	font-size: 12px;
	font-family: 'Open Sans';
}

.oglasi-tab-odgovor {
	@extend .oglasi-tab-pitanje;
	width: 640px;
	float: right;
	position: relative;

	&::before {
		content: '';
		background: url('/img/arrow.png') no-repeat;
		width: 20px;
		height: 75px;
		position: absolute;
		left: -20px;
	}

	.oglasi-pitanje-msg {
		background: #ffffeb;
		width: 451px;
		min-height: 86px;
	}

	.oglasi-pitanje-msg-header {

		&::after {
			border-right-color: #ffffeb;
		}
	}
}

.oglasi-tab-postavi-pitanje {

	float: left;

	.oglasi-pitanje-user {
		border: 2px solid #e2e2e2;
		border-right: 0;
	}

	.oglasi-pitanje-msg {
		border-top: 2px solid #e2e2e2;
		border-right: 2px solid #e2e2e2;
		border-bottom: 2px solid #e2e2e2;
		width: 483px;
		height: 120px;
	}

	textarea {
		width: 450px;
		height: 65px;
		border: 1px solid #e2e2e2;
		margin-top: 5px;
		margin-left: 5px;
		padding: 5px;
		font-family: 'Open Sans';
		font-size: 12px;
		resize: none;
	}

	::-webkit-input-placeholder {
		color: #ddd;
	}

	:-moz-placeholder {
		color: #ddd;
	}

	::-moz-placeholder {
		color: #ddd;
	}

	:-ms-input-placeholder {
		color: #ddd;
	}

	button {
		float: right;
		margin-top: 2px;
		margin-right: 12px;
		font-family: 'Trebuchet MS';
		font-size: 14px;
		font-weight: bold;
		border: 0;
		background: $color-text;
		color: $color-white;
		padding: 5px;
		position: relative;
		cursor: pointer;

		@include hover-active-focus {
			background: $color-primary;

			&::before {
				border-bottom-color: $color-primary;
			}

			&::after {
				border-top-color: $color-primary;
			}
		}

		&::before {
			content: '';
			position: absolute;
			left: -4px;
			top: 0;
			width: 0;
			height: 0;
			border-bottom: 26px solid $color-text;
			border-left: 4px solid transparent;
		}

		&::after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			top: 0;
			right: -4px;
			border-top: 26px solid $color-text;
			border-right: 4px solid transparent;
		}
	}

}

.oglasi-tab-registruj-se {
	float: left;
	font-family: 'Trebuchet MS';
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	width: 100%;
	margin-top: 10px;
	border-top: 2px solid $color-text;
	padding: 8px;

	a {
		position: relative;
		background: $color-text;
		color: $color-white;
		padding: 5px 15px;
		margin-right: 5px;
		margin-top: 10px;

		@include hover-active-focus {
			background: $color-primary;

			&::before {
				border-bottom-color: $color-primary;
			}

			&::after {
				border-top-color: $color-primary;
			}
		}

		&::before {
			content: '';
			position: absolute;
			left: -4px;
			top: 0;
			width: 0;
			height: 0;
			border-bottom: 26px solid $color-text;
			border-left: 4px solid transparent;
		}

		&::after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			top: 0;
			right: -4px;
			border-top: 26px solid $color-text;
			border-right: 4px solid transparent;
		}
	}
}

.oglasi-call-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: #00c853;
	z-index: 2;
	padding: 1rem;

	p {
		text-transform: uppercase;
		color: $color-white;
		font-size: 1rem;
	}

	span {
		position: absolute;
		left: .5rem;
		top: .25rem;

		.fil0 {
			fill: $color-white;
		}
	}
}