.registracija-content-form {
	@include for-mobile-and-tablet { padding: .5rem; }
}

.registracija-korisnik-form {
	@include for-mobile-and-tablet { width: 100%; }
}

.form-email,
.form-ime,
.form-ime-prezime,
.form-pol,
.form-industrija,
.form-drzava,
.form-lokacija,
.form-telefon,
.form-contact,
.form-sifra {
	.form-group {
		width: 48%;

		@include for-mobile { width: 100%; }
		@include for-tablet { width: 50%; }
	}
}

.form-pol {
	.form-group {
		@include for-tablet { width: 20%; }
	}
}

// .form-contact {
// 	@include for-mobile-and-tablet { width: 100%; }
// }

.form-telefon {
	&__row {
		@include for-mobile-and-tablet { flex-wrap: wrap; }
	}

	input[type='text'] {
		@include for-mobile-and-tablet { width: 75%; }
	}

	.select-wrap {
		@include for-mobile-and-tablet { width: 25%; }
	}

	.form-group--phone {
		@include for-mobile-and-tablet { width: 85%; }
	}

	.form-telefon__hideBtn {
		@include for-mobile-and-tablet {
			width: 10%;
			margin: 12px 0 12px 8px;
		}
	}
}

.info-verifikacija {
	@include for-mobile-and-tablet { position: static; }
}
