.garaza-item-content {
	@extend .moji-oglasi-item-content;

	width: 60%;

}
	
.garaza-akcije {
	float: left;
	width: 100%;
	display: flex;

	.garaza-akcije-item {
			flex-grow: 1;
			margin: 0 1px;
			i {
					font-size: 18px;
					margin-right: 2px;
			}

			a {
					width: 100%;
					color: $color-text;
					background: #eee;
					border: 1px solid #d9d9d9;
					padding: 3px 10px;
					text-align: center;
					font-family: 'Trebuchet MS';
					font-weight: bold;

					@include hover-active-focus {
							color: $color-white;
							background: $color-primary;
					}
			}
	}
}

.garaza-item-content-info {
	@extend .moji-oglasi-item-content-info;

	width: 100%;

	span {
			color: #000;
			float: right;
			margin-right: 5px;
	}
}

.garaza-item-right {
	float: right;
	text-align: right;

	button {
			color: $color-text;
			background: #eee;
			border: 1px solid #d9d9d9;
			padding: 3px 10px;
			text-align: center;
			font-family: 'Trebuchet MS';
			font-weight: bold;
			cursor: pointer;

			@include hover-active-focus {
					color: $color-white;
					background: $color-primary;
			}

			i {
					font-size: 18px;
					margin-right: 2px;
			}
	}
}

.garaza-item-slika-container {
	position: relative;
	float: left;
	width: 40%;
}

.garaza-item-slika {
	width: 270px;
	height: 203px;

	@media (min-width: 1199px) {
			width: 360px;
			height: 270px;
	}
	background: no-repeat center center;
	background-size: cover;

	&.detail {
			width: 248px;
			height: 186px;
	}
}

.garaza-registracija {
	float: left;
	width: 100%;
	padding: .3rem;

	&-inactive {
			width: 100%;
			display: flex;
			justify-content: space-around;
			text-transform: uppercase;
			text-align: center;
			font-weight: bold;
			color: $color-white;

			&__first, &__second {
					width: 49.52%;
					padding: .3rem;
					border-radius: 4px;
					display: flex;
					justify-content: center;
					align-items: center;
			}

			&__first {
					background-color: #e91c23;
			}
	
			&__second {
					background-color: #139e00;
			}
	}

	h3 {
			position: absolute;
			left: 41.4%;
			top: 162px;
	}
}