@import '../abstracts/functions';
@import '../abstracts/mixins';
@import '../abstracts/variables';
@import 'typography';

body {
    font: $font-size $font-stack;
    color: $color-text;
    background: $color-bg;
}

html, body {
  // padding: 0 0 40px 0;
  text-rendering: optimizeLegibility;

  @include for-mobile-and-tablet {
    padding: 0;
  }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.container, .container__app {
    margin: 0 auto;
    background: $color-white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, .75);
    overflow: hidden;
    position: relative;
}

.container {
	width: 100%;

	@include for-desktop {
		width: 1200px;
	}
}

.container__app {
  width: 365px;
}

.clearfix {
  @include clearfix;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
  }

  &.classic {
    list-style: disc;
    margin-top: 10px;
    margin-left: 40px;
    li {
        display: list-item;
    }
  }
}

@include selection($color-primary, #fff);

blockquote::before {
  content: open-quote;
}

blockquote::after {
  content: close-quote;
}

:lang(en) q {
  quotes: "“" "”";
}

:lang(fr) q {
  quotes: "«" "»";
}

:lang(pl) q {
  quotes: "„" "”";
}

img {
  max-width: 100%;
  vertical-align: middle;
}

.tooltip {
  width: 110px;
  height: 30px;
  position: absolute;
  z-index: 9999;
  background: rgba(0, 0, 0, .7);
  color: #fff;
  line-height: 30px;
  text-align: center;
  top: 41px;
  left: -35px;
  display: none;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(0, 0, 0, .7);
    position: absolute;
    left: 50%;
    top: -5px;
    transform: translateX(-50%);
  }
}
