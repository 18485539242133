.user-cart-section {
	margin-left: auto;
	margin-right: auto;
	margin-top: 1rem;

	table {
		width: 100%;
		text-align: center;

		border: 1px solid $color-grey-dark;
		th, td {
			border: 1px solid $color-grey-dark;
		}
		border-collapse: collapse;
	}

	.user-cart-header {
		height: 2rem;
		font-size: 18px;
	}

	.user-cart-items-header {
		background-color: $color-grey-light;
	}

	.user-cart-remove {
		cursor: pointer;
	}

	.user-cart-update-amount {
		display: inline-block;
		margin: .7rem;
		cursor: pointer;

		border-radius: 50%;
		width: 18px;
		height: 18px;
		color: $color-white;
		text-align: center;

		&__increase {
			background-color: $color-green;
		}

		&__decrease {
			background-color: $color-secondary;
			line-height: 18px;
		}
	}

	.user-cart-purchase-button {
		padding: 5px 12px;
		background-color: $color-orange;
		color: $color-white;
		font-weight: bold;
		border: none;
		outline: none;
		border-radius: 4px;
		cursor: pointer;

		&__full-width {
			padding: 12px;
			width: 100%;
		}
	}
}

.cart-history-item {
	margin-top: 2rem;
	padding: .2rem;
	border: 1px solid $color-bg;

	&>header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;
		border-bottom: 1px solid $color-bg;
		background-color: $color-primary;
		
		&>span {
			font-size: 1rem;
			padding: .2rem;
			font-weight: bold;
			color: $color-white;
		}
	}

	&-ads {
		padding: .2rem;
		margin-bottom: 1rem;
		overflow: auto;
	}

	&-ads-seller {
		margin-bottom: .6rem;
		font-size: 1.2rem;
	}

	&-ads_item {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid $color-bg;
		padding: .2rem;

		&__left {
			width: 70%;
		}

		&__right {
			width: 30%;
			
			display: flex;
			justify-content: space-between;
		}
	}

	&-ads-total {
		font-weight: bold;
		float: right;
	}

	&-total {
		display: flex;
		justify-content: space-between;
		background-color: $color-grey-light;
		margin-top: 2rem;

		span {
			margin: .8rem auto;
			font-weight: bold;
			font-size: 1rem;
		}
	}

	footer {
		float: none;
		margin: 0;
		padding: .2rem;
		background-color: $color-white;
		text-align: center;
		border: 1px solid $color-bg;
		cursor: pointer;

		a {
			text-decoration: none;
			color: $color-text;
			font-size: 1.2rem;
		}

		@include hover-active-focus {
			background-color: $color-orange;

			a {
				color: $color-white;
			}
		}
	}
	
	&__details {
		margin-top: 0;

		.cart-history-item-total {
			background-color: $color-orange;
		}

		footer {
			cursor: default;
			color: $color-text;

			@include hover-active-focus {
				background-color: $color-white;
	
				a {
					color: $color-text;
				}
			}
		}
	}

	&-address {
		display: flex;
		flex-direction: column;

		header {
			background-color: $color-green;

			h3 {
				color: $color-text;
				font-size: 1rem;
				margin: .8rem 0;
			}
		}

		&_row {
			display: flex;
			justify-content: center;

			span {
				flex: 1;
				border: 1px solid $color-bg;
			}
		}
	}
}

.cart-history-back-button {
	margin: 1rem 0;
	outline: none;
	border: none;
	padding: .5rem;
	color: $color-white;
	background-color: $color-primary;
	border-radius: 4px;
	cursor: pointer;
}