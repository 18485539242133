.pretraga-prodavnica {
	@extend .pretraga-sms-oglasa;
}

.pretraga-prodavnica-header {
	@extend .pretraga-sms-oglasa-header;
}

.broj-prodavnica {
	@extend .broj-oglasa;
}

.pretraga-prodavnica-form {
	@extend .pretraga-sms-oglasa-form;

	.users-list {
		&__filter-row {
			width: 100%;

			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			margin-bottom: 3px;

			button {
				margin-left: -4%;
			}
		}

		&__select {
			width: 33%;
			margin-right: 3px;
			display: inline-block;

			select {
				width: 100%;

				&:disabled {
					color: #a395a4;
				}
			}

			optgroup {
				font-family: 'Calibri';
				color: #a395a4;
				font-weight: 300;
			}

			option {
				font-family: 'Calibri';
				color: #666;
			}

			&_last {
				margin-right: 0;
			}
		}
	}

	.users-list__search {
		display: inline-block;
		width: 62.5%;

		input[type="text"] {
			width: 100%;
			line-height: 3;
		}

		&_short {
			width: 28%;
		}
	}

	.users {
		&__filter-row {
			width: 96.5%;
		}
	}
}

.prodavnice-content {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	position: relative;
	min-height: 125px;
	justify-content: space-between;
}

.user-item {
	width: 33%;
	border: 1px solid rgb(97, 96, 96);
	background: #fff;
	margin-top: 3px;
	font-family: 'Trebuchet MS';
	position: relative;

	.user-header {
		width: 100%;
		background: #f9f9f9;
		font-weight: bold;
		text-transform: uppercase;
		text-align: center;
		border-bottom: 1px solid #666;

		a {
			color: $color-text;

			@include hover-active-focus {
				color: $color-primary;
			}
		}
	}

	.user-content {
		padding: 2px;
		display: flex;
		flex-direction: row;
	}

	.user-img {
		width: 104px;
		position: relative;

		@media (min-width: 1199px) {
			width: 136px;
		}

		a {
			width: 103px;
			height: 77px;
			overflow: hidden;

			@media (min-width: 1199px) {
				width: 135px;
				height: 100px;
			}
		}

		img { width: 100%; height: 100%; object-fit: contain; object-position: center; }
	}

	.user-info {
		float: left;
		width: 117px;
		padding: 5px;

		a {
			color: $color-text;
		}
	}

	.user-status {
		font-size: 12px;
		display: flex;
		justify-content: space-between;

		.user-online-status {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			display: inline-block;
			margin-right: 5px;

			&.offline {
				background: #e91c23;
			}

			&.online {
				background: #139e00;
			}
		}
	}

	.user-broj-artikala {
		display: inline-block;
		background: #eee;
		border-radius: 4px;
		margin: 9px 10px;
		width: 100px;
		text-align: center;

		a {
			padding: 3px 5px;
		}

		@include hover-active-focus {
			background: $color-primary;

			a {
				color: $color-white;
			}
		}
	}

	.user-lokacija {
		display: flex;

		i {
			vertical-align: middle;
			margin-right: 3px;
		}

		p {
			display: inline-block;
			vertical-align: middle;
			line-height: normal;
		}
	}

	&.pro-user {
		background: #fffff0;

		.user-header {
			background-color: #ffd740;

			&::after {
				content: '';
				background: url(/img/sprite.png) no-repeat;
				background-position: 0px -40px;
				width: 34px;
				height: 12px;
				display: inline-block;
				float: left;
				margin: 2px;
			}
		}
	}

	&.even {
		margin-right: 33.5%;
	}
}

.zastupnik-item {
	@extend .user-item;

	width: 49.5%;

	.user-content {
		position: relative;
	}

	.user-info {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		width: 75px;
	}

	.user-broj-artikala {
		width: 65px;
		margin: 0;
	}

	.zastupnik-brands {
		position: absolute;
		right: 0;
		display: flex;
		flex-flow: row-reverse wrap;
		width: 140px;
		justify-content: space-evenly;
	}

	.user-lokacija {
		padding: 0 2px;
	}
}

.pretraga-oglasa-prodavnice-header {
	@extend .pretraga-prodavnica-header;
	padding: 15px;
	padding-bottom: 0;
	float: left;
	width: 100%;
}