/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
	width: 2.5rem;
	height: 2rem;
	right: 1rem;
	top: 1.25rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  padding: 1.7em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
	color: #b8b7ad;
	display: flex;
	flex-wrap: wrap;
	height: auto !important;
	margin-top: 1rem;
}

/* Individual item */
.bm-item {
	display: flex;
	width: 32.6%;
	text-align: center;
	justify-content: center;
	background: #DDDDDD;
	margin: 1px;
	align-items: center;
	height: 5rem;

	&-half { width: 49%; }

	&> a {
    font-size: 1.2rem;
		width: 100%;
		height: 100%;
		display: flex;
    flex-direction: column;
    justify-content: space-between;
	}

	&__text {
		color: $color-text;
		height: 25px;
		background-color: #C0C0C0;
		font-size: 14px;
		display: flex;
    justify-content: center;
    align-items: center;
	}

	i {
		font-size: 2.5rem;
    color: $color-text;
	}

	&-bottom {
		width: 100%;
		background: #ffffff;
		border-bottom: 1px solid #C0C0C0;
		border-top: 1px solid transparent;
		height: auto;
		padding: .5rem;
	
		&> a {
			color: #fff;
			font-size: 1.2rem;
			width: 100%;
			height: auto;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
		}
	
		&__text {
			background-color: $color-white;
			color: $color-text;
			margin-left: .5rem;
			font-size: 16px;
		}

		i {
			font-size: 25px;
		}

		&.active {
			background-color: $color-text;
	
			.bm-item-bottom__text {
				color: $color-white;
				background-color: $color-text;
			}
		}
	}

	&__text-right {
		margin-left: auto;
		margin-right: 1rem;
		font-size: 18px;
		background-color: $color-primary;
		padding: 0.3rem 1rem;
		border-radius: 4px;
	}

	&.active {
		background-color: $color-text;

		.fil0, .fil1 {
			fill: $color-white;
		}

		.bm-item__text {
			color: $color-white;
			background-color: $color-primary;
		}

		i {
			color: $color-white;
		}
	}
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
