/*
 * INDEX STYLE
*/
@import "../abstracts/variables";

main {
  position: relative;
  display: flex;

  &.forApp {
    .main-content {
      width: 100%;
      margin: 0;
    }
  }

  @include for-mobile-and-tablet {
    flex-wrap: wrap;
  }
}

.main-content {
    width: 692px;
    margin: 3px 2px 1px 3px;
    float: left;
    position: relative;

    @include for-laptop-and-desktop {
        width: 74.5%;
    }

    @include for-mobile-and-tablet {
        float: none;
        width: 100%;
        margin: 0;
    }
}

.main-content-extended {
  width: 100%;
  margin: 3px 2px 1px 3px;
  float: left;
  position: relative;

  @include for-mobile-and-tablet {
    margin: 0;
  }
}

.category-items {
    display: flex;
    flex-direction: row;
}

.sve-kategorije-btn {
    width: 26%;
    float: left;
    position: relative;

    i {
        position: absolute;
        right: 12px;
        top: 15px;
    }

    @include hover-active-focus {
        .kategorije-pretraga-mega-menu {
            visibility: visible;
        }
    }
}

.search-dropdown {
    width: 439px;
    display: inline-block;
    position: absolute;
    background-color: #f9f9f9;
    border: 1px solid #c0c0c0;
    left: 176px;
    top: 37px;
    z-index: 100;

    p {
        margin: 2px 5px;
        opacity: .6;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
    }

    &__profile {
        left: 355px;
        top: 67px;
    }
}


/*
 * PORUKE STYLE
*/
.poruke-pretraga {
    width: 100%;
    background: #eee;
    border: 1px solid #d9d9d9;
    padding: 5px;

    .link-nazad {
        height: 34px;
        height: 34px;
        background: $color-white;
        color: $color-text;
        text-transform: uppercase;
        border: 1px solid #d9d9d9;
        font-family: 'Trebuchet MS';
        font-weight: bold;
        padding: 5px;
        float: right;
        line-height: 23px;

        i {
            font-size: 20px;
            margin-right: 5px;
            margin-top: -3px;
        }

        @include hover-active-focus {
            color: $color-white;
            background: $color-primary;
        }
    }

    .link-do-oglasa {
        height: 34px;
        background: $color-white;
        color: $color-text;
        border: 1px solid #d9d9d9;
        font-family: 'Trebuchet MS';
        font-weight: bold;
        padding: 5px 10px;

        i {
            font-size: 20px;
            margin-right: 5px;
        }

        @include hover-active-focus {
            color: $color-white;
            background: $color-primary;
        }
    }

    option {
        height: 20px;
        border: 1px solid #d9d9d9;
    }

    select, option {
        font-weight: bold;
        width: 220px;
        border-radius: 0;
        text-transform: uppercase;
        font-family: 'Trebuchet MS';

        option {
            position: relative;

            span {
                &::after {
                    content: "aaa";
                    position: absolute;
                    left: 5px;
                    top: 1px;
                    width: 10px;
                    height: 10px;
                    font-size: 15px;
                }

            }
        }
    }

    .input-pretraga {
        display: inline-block;
        float: right;

        input {
            height: 34px;
            padding: 10px;
            width: 295px;
            border: 1px solid #c0c0c0;
        }

        button {
            background: none;
            border: 0;
            margin-left: -10px;
            cursor: pointer;
            line-height: 32px;
        }

        i {
            background: $color-text;
            width: 34px;
            height: 34px;
            color: $color-white;
            text-align: center;
            font-size: 24px;
        }
    }
}

.heading-lead {
    width: 100%;
    margin-bottom: 10px;
}

.form-group-fullWidth {
    width: 100% !important;

    input {
        width: 100% !important;
    }

    &__error-text {
        color: #e91c23;
        font-weight: bold;
        font-size: 1rem;
        padding: 2px;
    }
}

.input-addon {
    background: #ccc;
    color: $color-text;
    float: left;
    width: 40px;
    height: 34px;
    position: absolute;
    right: 0;
    // bottom: 0;
    text-align: center;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    line-height: 32px;
}

.oglas-form-header {
    width: 100%;
    float: left;
    color: $color-primary;
    text-transform: uppercase;
    font-family: 'Trebuchet MS';
    font-weight: bold;
    font-size: 14px;
    margin-top: 25px;
    margin-bottom: 20px;
    border-bottom: 1px solid $color-text;
    padding-bottom: 5px;
}

.checkbox-tooltip {
    display: inline-block;
    vertical-align: top;
    margin-left: 3px;
    font-size: 12px;
    cursor: pointer;
    position: relative;

    .cb-tooltip-content {
        display: none;
        position: absolute;
    }

    @include hover-active-focus {
        .cb-tooltip-content {
            display: block;
            font-size: 14px;
            padding: 5px;
            border-radius: 3px;
            position: absolute;
            width: 200px;
            top: 20px;
            left: 20px;
            z-index: 9999;
            background: rgba(0, 0, 0, 0.7);
            color: #fff;
        }
    }

}

.dodaj-model-btn {
    float: left;
    color: $color-text;
    display: inline-block;
    height: 34px;
    padding-top: 8px;

    @include hover-active-focus {
        color: $color-primary;
    }

    span {
        color: $color-primary;
        font-weight: bold;
    }
}

.obrisi-model-btn {
    float: left;
    display: inline-block;
    color: $color-text;

    &:hover {
        color: #e91c23;
    }

    &:active {
        color: #e91c23;
    }

    span {
        color: #e91c23;
        font-weight: bold;
    }
}

.form-group-file {
    float: left;
    width: 100%;
    min-height: 116px;
    background: #f9f9f9;
    border: 2px dashed #c0c0c0;
    font-family: 'Trebuchet MS';
    font-weight: bold;
    text-align: center;
    position: relative;

    a {
        position: relative;
        background: $color-text;
        color: $color-white;
        padding: 13px;
        border-radius: 3px;
        margin-right: 5px;

        @include hover-active-focus {
            background: $color-primary;
        }
    }
}

.napomena-slike {
    float: left;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 20px;
}

.tipovi-oglasa {
    width: 100%;
    display: flex;
}

.tip-oglasa-item {
    background: #f9f9f9;
    border: 1px solid #c0c0c0;
    position: relative;
    margin-bottom: 3px;
    width: 33%;
    margin-right: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
        cursor: pointer;
        background: #101010;
        color: #fff;
        padding: 8px 30px;
        border-radius: 3px;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 10px;

        &.active {
            background: $color-primary;
        }

        @include hover-active-focus {
            background: $color-primary;
        }
    }
}

.tip-oglasa-naziv {
    width: 100%;
    color: #ff7800;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    border-top: 1px solid #c0c0c0;
    border-bottom: 1px solid #c0c0c0;
    padding: 3px 10px;
    margin-top: 15px;
    background: $color-white;
    line-height: 1.75rem;
}

.tip-oglasa-cena {
    text-align: center;
    font-size: 60px;
}

.tip-oglasa-lista {
    padding: 0px 10px;
    min-height: 80px;

    li {
        display: block;
        margin-bottom: 10px;

        span {
            position: relative;
            color: #ff7800;
            font-weight: bold;
            border-bottom: 1px solid #ff7800;
        }
    }
}

.btn-oglas-submit {
    float: left;
    width: 100%;
    font-family: 'Trebuchet MS';
    font-size: 22px;
    font-weight: bold;
    background: $color-text;
    border: 0;
    outline: 0;
    box-shadow: 0;
    cursor: pointer;
    text-transform: uppercase;
    color: $color-white;
    padding: 10px;
    border-radius: 3px;

    @include hover-active-focus {
        background: $color-primary;
    }
}

.dodaj-logo-group, .dodaj-banner-group {
    width: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
}

.logo-img, .banner-img {
    width: 50%;

    img {
        // max-height: 150px;
    }
}

.logo-btns, .banner-btns {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a, label {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 1.2rem;
        color: $color-text;
        background: #eee;
        border: 1px solid #d9d9d9;
        cursor: pointer;
        margin-left: .5rem;
        text-align: center;

        @include hover-active-focus {
            background: $color-primary;
            color: #fff;
        }

        i {
            margin-right: 5px;
            // vertical-align: bottom;
            font-size: 20px;
        }
    }
}

.logo-btns, .banner-btns {
    a, label {
        display: inline-block;
        padding: .75rem;
    }
}

.dodaci-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;

    .tip-oglasa-item {
        width: 33%;
        margin-right: 3px;

        a {
            &.active, &.pending {
                background: $color-primary;
                cursor: not-allowed;
            }

            &.pending {
                text-align: center;
                cursor: not-allowed;
                text-align: center;
                width: 9em;
            }

            &.disabled {
                cursor: not-allowed;
            }
        }
        
        &:nth-of-type(3n) {
            margin-right: 0px;
        }
    }

    &-heading {
        width: 100%;
        margin: 30px 0;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
}

.tip-oglasa-lista {
    .pro {
        padding-left: 40px;
        border: none;

        &::after {
            content: '';
            background: url(/img/sprite.png) no-repeat;
            background-position: 0px -40px;
            width: 34px;
            height: 12px;
            position: absolute;
            top: 3px;
            left: 0;
        }
    }

}

.registracija-adresar {
    .info-ime {
        border: none;
        padding: 0;
        float: left;
        width: 100%;
        position: relative;
        left: 0;

        textarea {
            background: #f9f9f9;
            border: 1px solid #c0c0c0;
            border-radius: 3px;
            resize: none;
            width: 100%;
            height: 120px;
        }
    }

    .fotografije-group {
        .item {
            width: 32%;
            margin-right: 2%;
            float: left;
            margin-bottom: 5px;
            margin-left: 0;

            &:nth-of-type(3n) {
                margin-right: 0;
            }

            .rotate-btn {
                cursor: pointer;
            }

            .remove-btn {
                cursor: pointer;
            }

            .ico-novost-pregledi {
                font-size: 12px;
                cursor: pointer
            }

            .img-container {
                height: 76px;
            }
        }
    }
}

.radno-vreme-od, .radno-vreme-do {
    float: left;
    width: 100%;
    margin-bottom: 15px;

    .item {
        width: 30% !important;
        float: left;
        margin-right: 5%;

        &:last-of-type {
            margin-right: 0;
        }

        input, select {
            width: 100%;
        }
    }
}

.logout-button {
    cursor: pointer
}

.profile-notifications {
    &__header {
        float: left;
        width: 100%;
    }
}

.removeBtn {
    float: right;
    padding-top: 8px
}

.grecaptcha-badge { visibility: hidden; }
