.subcategories-horizontal-menu {
	width: 100%;
	margin-top: 1rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.subcategories-horizontal-menu-item {
	display: flex;
	align-items: center;
	background-color: #eeeeee;
	padding: .2rem .2rem .3rem .7rem;
	border-radius: 2.5rem;
	margin-left: 1rem;
	margin-bottom: 8px;

	a {
		display: flex;
		align-items: center;
		color: $color-text;
		padding: .2rem;
	}

	p {
		margin: 0 .5rem;
	}

	i {
		font-size: 22px;
	}

	@include hover-active-focus {
		background-color: $color-primary;

		a {
			color: $color-white;

			.fil0, .fil1 {
				fill: $color-white;
			}
		}
	}

	&.active {
		background-color: $color-primary;

		a {
			color: $color-white;

			.fil0, .fil1 {
				fill: $color-white;
			}
		}
	}

	&.disabled {
		@include hover-active-focus {
			background-color: #eeeeee;
	
			a {
				cursor: not-allowed;

				color: $color-text;
	
				.fil0, .fil1 {
					fill: $color-text;
				}
			}
		}
	}
}