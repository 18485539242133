.discount-card, .partner-details-item {
	.card {
			width: 100%;
			background-color: #003263;
			float: left;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&-buttons {
					display: flex;
					flex-direction: column;
					margin-right: 10%;

					button {
							width: 14.5rem;
							height: 2.3rem;
							margin: .7rem 0;
							border: 1px solid #d9d9d9;
							border-radius: .2rem;
							text-transform: uppercase;
							background-color: $color-white;
							font-family: 'Trebuchet MS';
							font-size: 18px;
							font-weight: bold;
							color: rgb(148, 148, 148);
							cursor: pointer;

							@include hover-active-focus {
									background: #ddd;
									border: 1px solid #c0c0c0;
							}
					}
			}
	}

	.info {
			position: absolute;
			width: 340px;
			left: 60px;
			height: 75px;
			top: 186px;
			font-family: 'Open Sans';
			color: white;
			text-transform: uppercase;
			font-weight: bold;
			cursor: default;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			p {
					line-height: 100%;
			}

			&-user {
					font-size: 12px;
			}

			&-number {
					margin: 0;
					font-family: inherit;
					font-size: 25px;
			}

			&-validity-period {
					margin-top: 7px;
					span {
							&:last-of-type {
									margin-left: 49px;
							}
					}
			}
	}

	.qr-code {
			position: absolute;
			width: 56px !important;
			height: 56px !important;
			top: 65px;
			left: 326px;
	}

	.activate {
			position: absolute;
			background-color: #003263;
			width: 62%;
			height: 252px;
			color: $color-white;
			text-align: center;
			opacity: .9;

			img {
					position: absolute;
					left: 12px;
					top: 85px;
			}

			p {
					line-height: normal;
					letter-spacing: 2px;
			}

			&-link {
					position: absolute;
					top: 104px;
					left: 30px;
					width: 370px;
					font-weight: bold;
					cursor: pointer;
			}
	}

	.partners-list-container {
			border: 1px solid #c0c0c0;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
	}

	.partners-item-container {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;
			padding: 4px;
	}

	.partners-item-slika-container {
			position: relative;
			float: left;
			width: 40%;
	}
	
	.partners-item-slika {
			width: 200px;
			height: 150px;
			background: no-repeat center center;
			background-size: cover;
	}

	.partners-item-contact {
			p {
					display: flex;
					height: 25px;
			}
	}

	.partners-item-discount {
			display: flex;
			flex-direction: column;
			font-weight: bold;
			font-size: 1.2rem;
			text-transform: uppercase;
			text-align: center;
			height: 118px;
			justify-content: start;

			span {
					background-color: red;
					padding: 7px;
					color: $color-white;
					min-width: 150px;
			}
			
			a {
					background-color: #999999;
					padding: 7px;
					text-decoration: none;
					text-transform: uppercase;
					color: $color-white;
					margin-top: .3rem;
			}
	}

	.partners-filter {
			float: left;
			width: 100%;
			border: 1px solid #c0c0c0;
			padding: 15px;

			.heading {
					line-height: 120%;
					color: #333;
					font-family: 'Trebuchet MS';
					font-weight: bold;
					text-transform: uppercase;
			}

			&-fields {
					display: flex;

					.form-group {
							width: 30%;
							margin-top: 20px;
							margin-right: 12px;
							label {
									display: inline-block;
							}
							
							select {
									height: 38px;
									width: 100%;
							}

							input {
									display: inline-block;
									width: 100%;
									background: #f9f9f9;
									border: 1px solid #c0c0c0;
									border-radius: 3px;
									padding: 10px;
							}
					}

					button {
							position: relative;
							padding: 3px 6px 4px 7px;
							margin-left: -14px;
							height: 38px;
							background: $color-bg;
							border: 0;
							top: 20px;
							cursor: pointer;
					}
			}
	}
}

.discount-card-verification {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 1rem;
	margin-left: 7rem;
	width: 30rem;
	height: 7rem;
	float: left;
	border: 1px solid #ddd;
	border-radius: .3rem;
	color: $color-white;
	text-align: center;

	&.verified {
			background: #15b500;
	}

	&.not-verified {
			background: #e91c23;
	}

	h1 {
			text-transform: uppercase;
			padding: .3rem;
			margin: 0;
	}
}

.partner-details {
	&-container {
			border: 1px solid #c0c0c0;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
	}

	&-menu {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;
			padding: .1rem;

			button {
					width: 33%;
					font-family: 'Trebuchet MS';
					font-size: 14px;
					font-weight: bold;
					border: 0;
					background: #ddd;
					color: $color-text;
					padding: 5px;
					text-transform: uppercase;
					cursor: pointer;

					&.active {
							color: $color-white;
							background: $color-primary;
					}

					@include hover-active-focus {
							color: $color-white;
							background: $color-primary;
					}
			}
	}

	&-main {
			padding: .3rem;

			.location {
				 border: 1px solid #c0c0c0;
				 margin-bottom: 1rem;

					.header {
							text-align: center;
							text-transform: uppercase;
					}

					.info, .worktime {
							padding: .3rem;
							position: relative;
							
							&.with-map {
									margin-bottom: 17rem;
							}
					}

				 .worktime {
							display: flex;
							justify-content: space-around;
							text-align: center;
							// margin-top: 12rem;

							.hours {
									border: 1px solid #ddd;
									padding: .2rem;
									width: 15%;

									p {
											&:first-child {
													border-bottom: 1px solid #ddd;
											}
									}
							}
				 }
			}

			.partner-ads-btn {
					width: 100%;
					background-color: #11a73f;
					color: $color-white;
					font-weight: bold;
					text-align: center;
					text-transform: uppercase;
					padding: 10px;
					border-radius: 2px;

					@include hover-active-focus {
							background-color: $color-primary;
					}
			}
	}
}

.discount-card-price {
	display: flex;
	margin-top: .3rem;
	font-family: 'Tahoma';
	font-size: 18px;
	padding: 2px 6px;
	padding: 0;
	font-weight: bold;

	img {
			margin-right: .3rem;
	}

	&-small {
		display: flex;
		float: right;
		margin-right: 10px;
		flex-direction: column;
		align-items: center;
		
		img {
				width: 15px;
		}
		span {
			font-family: 'Trebuchet MS';
			color: #101010;
			font-weight: bold;
			display: flex;
			align-items: center;
			padding: 1px 4px;
			position: relative;
		}
		p {
			font-size: 9px;
			color: #999999;
			line-height: 80%;
			text-transform: uppercase;

			@media (min-width: 1199px) {
				font-size: 11px;
			}
		}
	}
}