.parent-categories-dropdown-menu {
	position: absolute;
	left: 0;
	top: 100%;
	width: 100%;
	background-color: #ddd;
	padding: 0 3px;
	z-index: 1020;
	border-bottom: 3px solid $color-text;
	display: none;
}

.parent-categories-dropdown-menu-container {
	width: 100%;
	padding: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.parent-categories-dropdown-menu-items {
	width: 74%;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	justify-content: flex-start;
}

.parent-categories-dropdown-menu-item {
	display: flex;
	align-items: center;
	padding: 8px;
	margin-right: 5%;
	margin-top: 5px;
	border-radius: 4px;
	cursor: pointer;

	p {
		display: inline-block;
		font-size: 18px;
		margin-left: 5px;
	}

	&:nth-of-type(4n + 4) {
		margin-right: 0;
	}

	@include hover-active-focus {
		background-color: $color-primary;
		color: $color-white;
		.fil0, .fil1 {
			fill: $color-white;
		}
	}

}

.parent-categories-dropdown-menu-banner {
	width: 26%;
}
