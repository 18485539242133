.filter-buttons {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 1%;
	margin-top: 10px;

	&-toggle-detail, &-search {
		width: 49%;
		font-size: 20px;
		padding: 8px;
		border-radius: 4px;
		text-align: center;
	}

	&-toggle-detail {
		background-color: $color-bg;
		color: $color-text;

		outline: none;
		border: none;
		cursor: pointer;
		font-family: 'Calibri', 'Open Sans', sans-serif;
		
		&__disabled {
			opacity: .3;
			cursor: not-allowed;
		}
	}

	&-search {
		font-family: 'Calibri', 'Open Sans', sans-serif;
		background-color: $color-text;
		color: $color-white;
		border: none;
		cursor: pointer;
	}

	.disabled {
		opacity: .8;
		cursor: not-allowed;
	}
}



.filter-navigation {
	display: flex;
	width: 100%;
	justify-content: space-between;

	&-item {
		width: 50%;
		border: 1px solid #eeeeee;
		padding: .5rem;

		a { display: flex; }

		&-text {
			display: flex;
			flex-direction: column;
			margin-left: .75rem;

			p {
				text-transform: uppercase;
				line-height: normal;
				color: $color-grey-dark;

				&:first-of-type { font-weight: 700; font-size: 1.25rem; }
				&:nth-of-type(2) { font-size: .95rem; }
			}
		}
	}
}
