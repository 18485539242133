.all-categories-with-letters {
	width: 100%;

	.parent-categories-items, .subcategories-items {
		margin-top: .2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: .6rem;

		@include for-mobile {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	.parent-categories-item, .subcategories-item {
    height: 80px;
    padding: 6px 0;
    background: #f7f7f7;
    border: 1px solid #ddd;
		text-align: center;
		font-size: 1rem;
		text-decoration: none;
		color: $color-text;
		cursor: pointer;

		img {
			width: 4rem;
			height: 3rem;
		}

		@include hover-active-focus {
			background: #ddd;
			border: 1px solid #c0c0c0;
		}
	}

	.parent-categories-item {
		display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
	}

	.categories-suggestions {
		background-color: $color-grey-light;
		padding: .2rem 0;

		&-container {
			padding: .3rem;
			margin: 1rem .8rem;

			li {
				margin-right: .5rem;
				font-size: 14px;

				a {
					text-decoration: none;
					color: $color-text;
					cursor: default;
				}

				&:last-of-type {
					a {
						font-weight: bold;
					}
				}
			}

			@include hover-active-focus {
				color: $color-white;
				background-color: $color-primary;
				cursor: pointer;

				a {
					color: $color-white;
					cursor: pointer;
				}
			}

			&__active {
				color: $color-white;
				background-color: $color-primary;
				cursor: pointer;

				li {
					a {
						color: $color-white;
						cursor: pointer;
					}
				}
			}
		}
	}
}

.subcategories-item {
	&.active {
		background-color: $color-primary;
		color: $color-white;
	}
}

.all-categories-with-letters-section {
	margin-bottom: 2rem;

	.categories-items {
		section {
			margin-bottom: 1rem;
			position: relative;

			.letter {
				font-size: 5em;
				line-height: 0;
				margin: 0;
				color: $color-grey-light;
				position: absolute;
				top: 3rem;
			}
		}
	}

	.categories-items-group {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-left: 3.5rem;
		margin-top: 1rem;
	}

	.categories-item {
		text-decoration: none;
		color: $color-text;
		text-align: center;
		width: 16rem;
		margin-bottom: 1rem;
		cursor: pointer;

		img {
			max-width: 3rem;
			max-height: 3rem;
		}

		@include hover-active-focus {
			background-color: $color-grey-light;
		}

		@include for-mobile-and-tablet {
			width: 30%;
		}
	}

	.letters {
		width: 100%;
		display: flex;
		// justify-content: center;
		margin-bottom: 2rem;
		overflow: auto;

		p {
			padding: 0.5rem;
			font-size: 1.4em;
			color: #ccc;
			cursor: pointer;

			@include hover-active-focus {
				color: $color-primary;
			}
		}
	}

	header {
		p {
			text-align: center;
			color: $color-primary;
			position: relative;

			&::before {
				content: '';
				background: url('/img/line-pattern.png');
				width: 14rem;
				height: 17px;
				position: absolute;
				left: 1px;
				top: 2px;
			}

			&::after {
					content: '';
					background: url('/img/line-pattern.png');
					width: 14rem;
					height: 17px;
					position: absolute;
					right: 2px;
					top: 2px;
			}
		}
	}

	button {
		display: flex;
    justify-content: center;
    align-items: center;
		width: 100%;
    margin: .4rem 0;
    font-family: Trebuchet MS;
		font-weight: 700;
		text-transform: uppercase;
    background: $color-primary;
    border: 0;
    outline: 0;
    color: $color-white;
    padding: .8rem;
    border-radius: 3px;
    cursor: pointer;
	}
}