.ios-modal {
	width: 100vw;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	background-color: #FFF;
	z-index: 19;

	p {
		font-size: 1em;
		text-align: center;
	}

	a {
		background-color: $color-primary;
		color: $color-white;
		text-decoration: none;
		margin-top: 1rem;
		border: none;
		outline: none;
		border-radius: 2px;
		padding: 1rem 2rem;
		cursor: pointer;
	}

	&__close {
		cursor: pointer;
		align-self: flex-end
	}
}