.sacuvane-pretrage-content {
	width: 100%;
}

.sacuvane-pretrage-akcije {
	width: 100%;
	display: flex;

	padding: 5px;
	background: #f9f9f9;
	border: 1px solid #d9d9d9;
	border-top: 0;

	p {
		display: inline-block;
		position: relative;

		&:first-of-type {
			margin-left: 4px;

			&::after {
				content: '';
				position: absolute;
				height: 15px;
				width: 1px;
				background: #d9d9d9;
				margin: 0 7px;
				top: 2px;
			}
		}

		&:nth-of-type(2) {
			margin-left: 8vw;
		}

		&:nth-of-type(3) {
			margin-left: auto;
		}
	}

	a {
		&:last-of-type {
			color: #999;
			margin-left: 20px;

			@include hover-active-focus {
				color: $color-text;
			}
		}

		&.oznaci-kao-neprocitano {
			margin-left: 5px;
			color: #333;

			&.neprocitano {
				color: #0077b5;
			}

			@include hover-active-focus {
				color: #0077b5;
			}

			i {
				margin-right: 5px;
			}
		}
	}
}

.sacuvane-pretrage-item {
	width: 100%;
	padding: 5px;
	border: 1px solid #d9d9d9;
	border-top: 0;
}

.sacuvane-pretrage-item-header {
	width: 100%;
	display: flex;

	p {
		&:first-of-type {
			font-weight: bold;
			text-transform: uppercase;
			font-family: 'Trebuchet MS';
		}
	}

	.akcije {
		margin-left: 8vw;

		a {
			color: #666;
			background: #f9f9f9;
			border: 1px solid #d9d9d9;
			border-radius: 4px;
			padding: 2px 6px;
			width: 75px;

			&:first-of-type {
				margin-right: 2px;

				@include hover-active-focus {
					background: $color-text;
				}

				i {
					margin-right: 2px;
				}
			}

			i {
				vertical-align: top;
				margin-right: 8px;
			}

			@include hover-active-focus {
				background: #e91c23;
				color: $color-white;
			}
		}
	}

	.vreme {
		margin-right: 3px;
		color: #333;
		margin-left: auto;
	}
}

.sacuvane-pretrage-item-content {
	width: 100%;

	li {
			display: block;
			margin-bottom: 4px;

			p {
					display: inline-block;
					width: 140px;
					vertical-align: top;
			}

			span {
					font-family: 'Trebuchet MS';
					font-weight: bold;
					display: inline-block;
					width: 520px;
			}
	}
}
