/*
 * Colors
*/

$color-primary: #54317E;
$color-secondary: #e91c23;
$color-green: #11a73f;
$color-text: #101010;
$color-bg: #d3d3d3;
$color-orange: #fb9a00;
$color-grey-dark: #777777;
$color-grey-light: #eeeeee;
$color-grey-button: #d6d7d7;
$color-white: #fff;
$pagination-item-color: #101010;
$pagination-active-item-color: #202020;

/*
 * Brand Colors
*/

$color-facebook: #3b5998;
$color-instagram: #3f729b;
$color-linkedin: #0077b5;
$color-google: #4285f4;
$color-google-plus: #dd4b39;
$color-twitter: #1da1f2;
$color-behance: #1769ff;
$color-dribbble: #444;
$color-github: #4183c4;
$color-skype: #00aff0;
$color-viber: #7c529d;
$color-whatsapp: #43d854;