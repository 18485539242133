.oglasi-item-tekst {
	@include for-mobile {
		width: 49%;
	}

	@include for-tablet {
		width: 33%;
		margin-right: 1px;
	}

	&-banner {
		width: 100%;
	}
}

.oglasi-items-tekst-cena-rent {
	@include for-mobile-and-tablet {
		flex-direction: column;
		justify-content: center;
    align-items: flex-start;
	}

	.cena {
		@include for-mobile-and-tablet {
			&:nth-of-type(2) {
				margin-left: 0;
				margin-top: 1vh;
			}
		}
	}
}

.oglas-item-slika {
	@include for-mobile-and-tablet {
		width: 100%;
    height: 145.5px;
	}
}

.oglasi-pagination-mobile {
	padding: .5rem .25rem .25rem .25rem;

	a, span {
		padding: 4vw;
		margin-right: .2rem;

		&::after {
			display: none;
		}

		&::before {
			display: none;
		}

		@include for-tablet { padding: 2vw; }
	}
}

.oglasi-filter-content-mobile {
	ul {
		position: fixed;
    bottom: 0;
    background-color: #101010;
    width: 100%;
		display: flex;
	}

	li {
		height: 8.5vh;
		width: 20%;

		a {
			height: 8.5vh;
			color: $color-white;
			display: flex;
    	flex-direction: column;
			align-items: center;
			justify-content: center;

			i { font-size: 5vh; }
			span { white-space: nowrap; font-size: 1.75vh; }

			.fil0 { fill: $color-white; }

			&.active, .active i { background-color: $color-primary; }
		}
	}
}
