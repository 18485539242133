.ogl-header {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	margin: -4px;

	@include for-mobile-and-tablet {
		display: none;
	}

	&-categories-horizontal-menu {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		background-color: $color-grey-light;
	}

	&__ad-details {
		width: 62.59rem;;

		@media (min-width: 1199px) {
			width: 75.1rem;
		}
	}

	@include keyframes(slideUp) {
		0% {
			margin-top: 0;
		}
		100% {
			margin-top: -100px;
		} 
	}

	.lifted-up {
		-webkit-animation: slideUp 1s forwards;
		animation: slideUp 1s forwards;
	}

	&-main {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: 10px;
	}

	.kategorije-pretraga {
		flex-direction: column;
		justify-content: space-between;
	}

	.dynamic-groups-items {
		display: flex;
		flex-direction: row;
	}

	.ogl-header-categories-horizontal-menu-item {
		display: flex;
		flex-direction: column;
    align-items: center;
		width: 8.33%;
		position: relative;
		text-align: center;
		cursor: pointer;
		border-bottom: 1px solid #bbbbbb;
		border-right: 1px solid #bbbbbb;
		color: $color-text;

		@include for-mobile {
			width: 50%;
		}


		&-icon {
			margin-top: 0.7rem;
			z-index: 2;
			.fil0, .fil1 {
				fill: $color-grey-dark;
			}
		}

		@include hover-active-focus {
			color: $color-white;
			background-color: $color-primary;

			.ogl-header-categories-horizontal-menu-item-icon {
				.fil0, .fil1 {
					fill: $color-white;
				}
			}

			p {
				background-color: $color-text;
			}
		}

		&.active {
			color: $color-white;
			background-color: $color-primary;

			.ogl-header-categories-horizontal-menu-item-icon {
				.fil0, .fil1 {
					fill: $color-white;
				}
			}

			p {
				background-color: $color-text;
			}
		}

		&.disabled {
			color: $color-text;
			background-color: initial;
			cursor: not-allowed;

			.ogl-header-categories-horizontal-menu-item-icon {
				.fil0, .fil1 {
					fill: $color-grey-dark;
				}
			}

			p {
				background-color: initial;
			}
		}

		p {
			width: 100%;
			font-family: 'Open Sans', sans-serif;
			font-size: 10px;
			line-height: 12px;
			text-transform: uppercase;
			padding-top: .2rem;
			padding-bottom: .2rem;
			margin-top: auto;
		}
	}

	.kategorije-item {
    position: relative;
    text-align: center;
    margin-right: 1px;
    overflow: hidden;

    &.active {
			a {
				color: $color-primary;
			}
			
			p {
				color: $color-white;
				background-color: $color-primary;
			}

			.kategorije-item-icon-svg {
				.fil0, .fil1 {
					fill: $color-primary;
				}
			}
		}

		a {
			color: $color-text;

			@include hover-active-focus {
				color: $color-primary;

				p {
					color: $color-primary;
					background-color: $color-grey-light;
				}

				.kategorije-item-icon-svg {
					.fil0, .fil1 {
						fill: $color-primary;
					}
				}
			}
		}

		p {
			margin-top: .5rem;
			padding: .2rem 1rem;
			border-radius: .8rem;
		}

		i {
			font-size: 25px;
		}

		&-group {
			i {
				font-size: 40px;
			}
		}

		&.disabled>a {
			cursor: not-allowed;

			@include hover-active-focus {
				color: $color-text;

				p {
					color: $color-text;
					background-color: initial;
				}

				.kategorije-item-icon-svg {
					.fil0, .fil1 {
						fill: $color-text;
					}
				}
			}
		}
	}

	.kategorije-item-icon-svg {
		height: 56px;
		position: relative;

		&>span {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.selected-groups-area {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 3rem;
		padding: 10px;

		&-item {
			display: flex;
			align-items: center;
			min-height: 40px;
			background-color: #eeeeee;
			padding: .2rem .5rem;
			border-radius: 1.4rem;
			position: relative;
		}

		p {
			margin: 0 3rem 0 .5rem;
		}

		img {
			&:first-of-type {
				min-height: 40px;
				max-height: 40px;
				padding: 4px;
			}

			&:last-of-type {
				position: absolute;
				right: .6rem;
				cursor: pointer;
			}
		}
	}
}