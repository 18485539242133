.ads-search-popup {
	position: relative;

	header {
		display: flex;
		align-items: center;

		input[type='text'] {
			width: 35rem;
			height: 3rem;
			margin-left: 20rem;
			padding: .5rem;
			font-size: 1.4rem;
			border: 1px solid black;
			border-radius: .3rem;
		}

		button {
			background: black;
			border-top-right-radius: 10%;
			border-bottom-right-radius: 10%;
			width: 2.95rem;
			height: 2.95rem;
			cursor: pointer;
			border: 0;
			color: #fff;
			font-size: 18px;
			margin-left: -3%;

			@include hover-active-focus {
				background-color: $color-primary;
			}
		}

		.categories-suggestions {
			position: absolute;
			z-index: 9;
			top: 3.8rem;
			left: 28rem;
			width: 35rem;

			background-color: $color-grey-light;
			padding: .2rem 0;

			ul {
				margin: 1rem .8rem;
				li {
					margin-right: .5rem;
					font-size: 14px;

					a {
						text-decoration: none;
						color: $color-text;
						cursor: default;
					}
				}
			}

			&-container {
				padding: .3rem;

				@include hover-active-focus {
					color: $color-white;
					background-color: $color-primary;
					cursor: pointer;

					a {
						color: $color-white;
						cursor: pointer;
					}
				}

				&__active {
					color: $color-white;
					background-color: $color-primary;
					cursor: pointer;

					a {
						color: $color-white;
						cursor: pointer;
					}
				}
			}
		}
	}

	&-main {
		display: flex;
		padding: 1rem;
	}

	&__left {
		width: 20%;
	}

	.ads-list {
		width: 80%;
	}

	.ad-item {
		display: flex;
    flex-direction: column;
	}
}
