.bought-items {
	min-height: 15rem;
}

.bought-item {
	margin-top: 2rem;

	&_row {
		display: flex;
		justify-content: center;

		span, button {
			flex: 1;
			border: 1px solid $color-bg;
			text-align: center;
			padding: .5rem;

			&:nth-of-type(2n) {
				border-left: none;
			}
		}
		
		.bought-item-action {
			cursor: pointer;
			font-weight: bold;

			&__first {
				background-color: $color-text;
				color: $color-white;
			}

			&__second {
				background-color: $color-orange;
			}

			&__third {
				background-color: $color-green;
				color: $color-white;
			}

			&__fourth {
				background-color: $color-bg;
			}

			&__disabled {
				cursor: not-allowed;
			}

			@include hover-active-focus {
				background-color: $color-primary;
				color: $color-white;
			}
		}

		&__header {
			background-color: $color-primary;
			color: $color-white;

			span {
				border: none;
			}
		}

		&__black {
			background-color: black;
			color: white;

			span {
				text-align: left;
				font-weight: bold;
			}
		}
	}
}