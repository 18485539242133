.category-horizontal-menu-mobile {
	@include for-laptop-and-desktop {
		display: none;
	}

	margin-top: 3px;

	.filter-btn {
    width: 98.5%;
		color: $color-text;
    background: $color-grey-button;
    border-radius: 4px;
    height: 2rem;
		
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
		text-align: center;
    font-weight: bold;
    text-transform: uppercase;
		margin: 0.5rem 0.2rem;
	}

	.sausage-buttons {
		display: flex;
		margin-bottom: .5rem;
		overflow: auto;
    padding-bottom: .5rem;
	}

	.sausage-button {
		display: flex;
		align-items: center;
		justify-content: space-between;
    background: $color-grey-button;
		padding: 0.2rem .5rem;
    border-radius: 1rem;
		margin-right: .5rem;

		&-close {
			margin-left: 3px;
		}
		
		p {
			white-space: nowrap;
		}
	}

	&-items {
		display: flex;
		overflow: auto;
    align-items: center;
	}

	&-item {
    display: flex;
    flex-direction: column;
		margin-right: 1px;
    justify-content: center;
    align-items: center;
		margin-bottom: auto;
		text-decoration: none;
		color: $color-text;

		p {
			width: 5.25rem;
			font-size: 12px;
			text-align: center;
			margin-top: auto;
			margin-bottom: 1rem;
		}

		.fil0, .fil1 {
			fill: $color-grey-dark;
		}

		&.active {
			.fil0, .fil1 {
				fill: $color-primary;
			}

			.svg-container {
				border: 3px solid $color-grey-button;
			}
		}
	}

	.svg-container {
		background: #eeeeee;
		width: 4rem;
    border-radius: 2rem;
    height: 4rem;
		display: flex;
    justify-content: center;
    align-items: center;
		margin-bottom: 5px;
	}

	.select-dropdown-picker {
		width: 98%;
    margin-left: auto;
    margin-right: auto;
    display: block;
	}

	.select-dropdown-selected {
		display: block;
	}
}