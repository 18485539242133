.polja-pretrage {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	label {
		font-size: 12px;
		font-family: 'Open Sans';
		display: block;
	}

	.ads-filter__swap {
		width: 100%;
		display: flex;
		flex-wrap: wrap;

		.ads-filter__row {
			margin-top: 0;

			&:last-of-type {
				margin-top: 0
			}
		}
	}

	.ads-filter__row {
		margin-bottom: 5px;
		display: flex;
		flex-wrap: wrap;
		width: 50%;

		.polja-pretrage-item {
				width: 43%;

				&.polja-pretrage-item-vodi-se {
						margin-top: 10px;
				}

				&.fullWidth {
					width: 100%;
					a {
							width: 200%;
							text-align: center;
					}
				}

				&:last-of-type {
						margin-right: 0;
				}
		}
	}
}

.detaljna-polja-pretrage {
	@extend .polja-pretrage;

	.ads-filter__row {

		&:last-of-type {

			margin-top: 15px;

			input,
			label {
					display: inline-block;
					vertical-align: top;
			}
		}
	}
}

.polja-pretrage-item {
	width: 22.5%;
	margin-right: 3.3%;
	margin-bottom: 8px;
	position: relative;

	label {
		padding-left: .5rem;
		font-size: 14px;
		font-family: 'Open Sans';
		white-space: nowrap;
		display: block;
	}

	.inline-label {
		display: inline-block;
		vertical-align: top;
	}

	.polja-pretrage-input {
		background: white;
		width: 100%;
		outline: none;
		border: 1px solid #777;
		padding: 1rem;
		height: 2.5rem;
		border-radius: 20px;

		font-family: 'Trebuchet MS';
		font-size: 16px;
		font-weight: bold;

		&+.input-addon {
			line-height: 38px;
			height: 38px;
			margin: 1px 1px 0 0;
		}
	}

	&:nth-of-type(4n + 4) {
			margin-right: 0;
	}

	.pretrazi-btn {
		background: $color-text;
		text-transform: uppercase;
		font-size: 20px;
		margin-top: 14px;
		padding: 6px 42px;
		color: $color-white;
		position: relative;
		height: 38px;

		i {
			font-size: 23px;
			margin-left: 8px;
			position: relative;
			top: -2px
		}

		&::before {
				content: '';
				position: absolute;
				left: -5px;
				top: 0;
				width: 0;
				height: 0;
				border-bottom: 38px solid #101010;
				border-left: 5px solid transparent;
		}

		&::after {
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				top: 0;
				right: -5px;
				border-top: 36px solid #101010;
				border-right: 5px solid transparent;
		}

		@include hover-active-focus {
			background: $color-primary;

			&::before {
				border-bottom-color: $color-primary;
			}

			&::after {
				border-top-color: $color-primary;
			}
		}

	}

	::-webkit-input-placeholder {
			color: #666;
			font-family: 'Open Sans';
			font-size: 11px;
	}

	:-moz-placeholder {
			color: #666;
			font-family: 'Open Sans';
			font-size: 11px;
	}

	::-moz-placeholder {
			color: #666;
			font-family: 'Open Sans';
			font-size: 11px;
	}

	:-ms-input-placeholder {
			color: #666;
			font-family: 'Open Sans';
			font-size: 11px;
	}

	label {
			margin-bottom: 2px;
	}

	select,
	.select-with-checkbox label {
			border-radius: 0;
			font-size: 11px;
			font-family: 'Open Sans';
			color: #666;
	}

	.select-with-checkbox {

			.select-dropdown {
					margin: 0;
					margin-top: -3px;
					width: 100%;
			}

			label {
					width: 100%;
					height: 30px;
					padding-top: 6px;

					&::after {
							content: '';
							font-family: 'Open Sans';
							font-size: 11px;
							color: #666;
					}
			}

	}

	&_swapps {
		margin-right: 30px !important;
		&:last-of-type {
			margin-right: 0px !important;
		}
	}

	&.empty {
			height: 47px;
	}

	&_equipment {
		width: 180px;
	}

	&_equipment:nth-of-type(4n) {
		margin-right: 30px;
	}
}

.input-range-item {
	display: flex;
	align-items: center;
	flex-direction: row;
	background: white;
	border-radius: 20px;
	border: 1px solid #777;
	height: 2.5rem;
	align-self: flex-end;
}

.polja-pretrage-item__input-range-wrapper, .polja-pretrage-item-cena {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.input-range-item {
		width: 48%;

		// &:nth-of-type(2) {
		// 	margin-top: auto;
		// }
	}

	label {
		width: 100%;
		white-space: nowrap;
	}
}

.polja-pretrage-item__input-range-measure {
	font-family: 'Trebuchet MS';
	font-size: 14px;
	color: #777777;
	font-weight: bold;
  padding-left: .15rem;
  padding-right: .15rem;
	margin-left: auto;
}

.polja-pretrage-item__input-range {
	width: 75%;
	background: rgba(0, 0, 0, 0);
	border: 0;
	padding: 8px;
	font-family: 'Trebuchet MS';
	font-weight: bold;
	font-size: 16px;

	&:last-of-type {
		margin-right: 0;
	}

	&.error {
		border: 1px solid #e91c23;
	}

	&::placeholder, &::-webkit-input-placeholder {
		font-family: 'Open Sans';
		font-size: 14px;
		font-weight: normal;
	}

	&:disabled { opacity: .3; }
}

.polja-pretrage-item__select-range {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;

	.select-range-item {
		width: 48%;

		.select-dropdown-picker {
			background-position: 90%;
		}

		&:nth-of-type(2) {
			margin-top: auto;
		}
	}

	label {
		min-height: 17px;
		white-space: nowrap;
	}
}

// .polja-pretrage-item-cena {
// 	position: relative;
// 	margin-left: -1px;
// }

.kategorije-pretrage-mobilni {
	@include for-mobile-and-tablet {
		.filter-btn {
			width: 98.5%;
			color: $color-text;
			background: $color-grey-button;
			border-radius: 4px;
			height: 1.7rem;
			
			display: flex;
			justify-content: center;
			align-items: center;
	
			font-size: 14px;
			text-align: center;
			font-weight: bold;
			text-transform: uppercase;
		}
	}
}

.text-search-mobile {
	form {
		width: 100%;
    position: relative;
		min-height: 11rem;
	}

	input {
		width: 100%;
    position: relative;
    height: 2.5rem;
    border: 0;
    border-bottom: 1px solid #D3D3D3;
    padding: 0 0 0 .5rem;
	}

	button {
		position: absolute;
    top: 0;
    right: 0;
    width: 5rem;
    color: $color-primary;
    font-size: 1.3rem;
    border: 0;
    height: 2.4rem;
	}
}
