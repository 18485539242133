.agents-content {
	width: 100%;
	display: flex;
	flex-direction: column;
	border: 1px solid #c0c0c0;
	min-height: 35rem;
	padding: .2rem;

	&>.oglasi-agent {
		padding: .3rem;
		border: 1px solid #c0c0c0;
		margin-bottom: .5rem;
		
		&>.oglasi-agent-info {
			margin-top: 0;
		}
	}

	.oglasi-agent-actions {
		margin-left: auto;
		margin-right: .2rem;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}
	
	.oglasi-agent-action {
		cursor: pointer;
	
		i {
			font-size: 2rem;

			@include hover-active-focus {
				color: $color-primary;
			}
		}
	}

	.no-agents {
		text-align: center;
		font-size: 2rem;
	}
}

.agents-delete-modal {
	width: 35rem;

	&__action {
    width: 20%;
    font-family: 'Trebuchet MS';
    font-size: 1rem;
    font-weight: bold;
    background: $color-text;
    border: 0;
    outline: 0;
    box-shadow: 0;
    cursor: pointer;
    text-transform: uppercase;
    color: $color-white;
    padding: 10px;
    border-radius: 3px;
		margin-top: .5rem;

    @include hover-active-focus {
      background: $color-primary;
    }

		&:nth-of-type(2) {
			margin-left: 1rem;
		}
	}
}

.agent-form {
	padding: 1rem;

	&>.form-group {
		width: 50%;
		margin-bottom: 1rem;

		label {
			display: block;
		}

		input {
			width: 14.7rem;
			background: #f9f9f9;
			border: 1px solid #c0c0c0;
			border-radius: 3px;
			padding: 10px;
		}
	}

	.form-group__error {
		color: #e91c23;
	}
}

.agents-button-back {
	float: right;
	display: flex;
	align-items: center;
	margin: .2rem;

	span {
		font-size: 20px;
	}

	i {
		cursor: pointer;
		margin-right: 5px;
		font-size: 20px;

		@include hover-active-focus {
			color: $color-primary;
		}
	}
}

.agent-image {
	margin-top: .5rem;
	margin-bottom: .5rem;

	&__actions {
		width: 14.7rem;

		a, label {
			display: inline-block;
			margin: .5rem;
			padding: .5rem;

			font-weight: 600;
			font-size: 16px;
			color: $color-text;
			background: #eee;
			line-height: 24px;
			border: 1px solid #d9d9d9;
			cursor: pointer;

			@include hover-active-focus {
					background: $color-primary;
					color: #fff;
			}

			i {
					margin-right: 5px;
					vertical-align: bottom;
					font-size: 20px;
			}
		}
	}
}
