.sms-ads {
	&__type {
		width: 20%;
		display: inline-block;
	}

	&__group {
		width: 20%;
		display: inline-block;
	}

	&__search {
		display: inline-block;
		width: 35%;
		height: 34px;
	}
}

.sms-oglasi-item-img {
	float: left;
	width: 45px;

	i {
		width: 40px;
		height: 40px;
		background: $color-white;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
		font-size: 27px;
		text-align: center;
	}
}

.sms-oglasi-item-content {
	float: left;
	width: 90%;
	font-family: 'Open Sans';
	font-size: 12px;
	position: relative;
	height: 100%;
}

.sms-oglasi-item-footer {
	width: 100%;
	margin-top: 5px;
	position: absolute;
	bottom: 0;
	left: 0;

	.sms-oglasi-mobilni {
			float: left;
	}

	.sms-oglasi-date {
			float: right;
			margin-right: 5px;
	}
}

.sms-oglasi-item {
	width: 100%;
	height: 78px;
	padding: 3px;
	margin-bottom: 2px;
	margin-left: 2px;
	margin-right: 2px;
	background: #f9f9f9;
	border: 1px solid #d9d9d9;

	&.premium-sms-oglas-item {
			background: #ffffeb;
	}

	&:last-of-type {
			margin-bottom: 0;
	}
}

.pretraga-sms-oglasa {
	float: left;
	width: 100%;
	border: 1px solid $color-text;
	border-top: 0;
	padding: 15px 18px;

	p {
			line-height: 120%;
			color: #333;
	}
}

.pretraga-sms-oglasa-header {
	font-family: 'Trebuchet MS';
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 5px;

	.heading {
			float: left;
	}

	.broj-oglasa {
			float: right;

			span {
					color: $color-primary;
			}
	}

}

.sms-oglasi-header {
	@extend .page-header;
}

.pretraga-sms-oglasa-form {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;

	margin-top: 5px;

	select {
			width: 100%;
			display: inline-block;
			margin-right: 20px;
			border-radius: 0;
			color: #666;
			height: 34px;
	}

	input[type="text"] {
			display: inline-block;
			width: 100%;
			height: 34px;
			background: #f9f9f9;
			padding: 10px;
			border: 1px solid #c0c0c0;
			outline: none;
	}

	button {
			position: relative;
			padding: 3px 6px 4px 7px;
			height: 34px;
			margin-left: -6%;
			cursor: pointer;
			background: #e91c23;
			border: 0;
			color: #fff;
			font-size: 24px;

			i {
					vertical-align: top;
			}
	}

	::-webkit-input-placeholder {
			color: #ccc;
			font-family: 'Calibri';
			font-size: 14px;
	}

	:-moz-placeholder {
			color: #ccc;
			font-family: 'Calibri';
			font-size: 14px;
	}

	::-moz-placeholder {
			color: #ccc;
			font-family: 'Calibri';
			font-size: 14px;
	}

	:-ms-input-placeholder {
			color: #ccc;
			font-family: 'Calibri';
			font-size: 14px;
	}
}

.sms-oglasi-content {
	@extend .registracija-content;
	flex-direction: column;
	padding: 3px 1px;
	min-height: 125px;
}
